type Props = {
  size?: number;
}

export default function Link(props: Props) {
  const { size = 28 } = props;

  return (
    <svg width={size} height={size} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="2" y="2" width="24" height="24" fill="white" fillOpacity="0.01" />
      <path fillRule="evenodd" clipRule="evenodd" d="M12.5853 15.8144C11.0232 14.2523 11.0232 11.7196 12.5853 10.1575L15.4137 7.32908C16.9758 5.76698 19.5084 5.76698 21.0705 7.32908C22.6326 8.89117 22.6326 11.4238 21.0705 12.9859L17.9593 16.0972C17.8031 16.2534 17.5498 16.2534 17.3936 16.0972C17.2374 15.941 17.2374 15.6877 17.3936 15.5315L20.5049 12.4202C21.7545 11.1706 21.7545 9.14444 20.5049 7.89476C19.2552 6.64508 17.2291 6.64509 15.9794 7.89476L13.1509 10.7232C11.9013 11.9729 11.9013 13.999 13.1509 15.2487L13.2217 15.3194C13.3779 15.4756 13.3779 15.7289 13.2217 15.8851C13.0654 16.0413 12.8122 16.0413 12.656 15.8851L12.5853 15.8144Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M15.4147 12.9856C16.9768 14.5477 16.9768 17.0804 15.4147 18.6425L12.5863 21.4709C11.0242 23.033 8.49155 23.033 6.92945 21.4709C5.36736 19.9088 5.36736 17.3762 6.92945 15.8141L10.0407 12.7028C10.1969 12.5466 10.4502 12.5466 10.6064 12.7028C10.7626 12.859 10.7626 13.1123 10.6064 13.2685L7.49514 16.3797C6.24546 17.6294 6.24546 19.6555 7.49514 20.9052C8.74482 22.1549 10.7709 22.1549 12.0206 20.9052L14.8491 18.0768C16.0987 16.8271 16.0987 14.801 14.8491 13.5513L14.7783 13.4806C14.6221 13.3244 14.6221 13.0711 14.7783 12.9149C14.9345 12.7587 15.1878 12.7587 15.344 12.9149L15.4147 12.9856Z" fill="white" />
    </svg>
  );
}
