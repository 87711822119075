interface Props {
  size?: number;
  strokeWidth?: number;
  className?: string;
  height?: number;
}


export default function Share(props: Props) {
  const { size = 20, strokeWidth = 1.4821, className } = props;
  const height = size / 20 * 16;
  
  return (
    <svg width={size} className={className} height={height} viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.6226 6.51128L12.7612 1.50186C12.3172 1.08441 11.7843 1.50186 11.7843 2.16978V4.67449C7.61037 4.67449 4.05805 7.09571 2.37069 10.3518C1.74904 11.4372 1.39381 12.6061 1.12738 13.7749C0.949767 14.6098 2.28189 15.0273 2.81473 14.2759C4.76851 11.3537 8.05441 9.43344 11.7843 9.43344V12.1886C11.7843 12.8565 12.3172 13.274 12.7612 12.8565L18.6226 7.84712C18.9778 7.51316 18.9778 6.84524 18.6226 6.51128Z" stroke="currentColor" strokeWidth={strokeWidth}/>
    </svg>
  );
}