export default function Linkedin() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.80539 8.48601C9.80539 9.2249 9.22484 9.80545 8.48595 9.80545C7.74706 9.80545 7.1665 9.2249 7.1665 8.48601C7.1665 7.74712 7.74706 7.16656 8.48595 7.16656C9.22484 7.16656 9.80539 7.74712 9.80539 8.48601ZM9.80539 10.861H7.1665V19.3055H9.80539V10.861ZM14.0276 10.861H11.3887V19.3055H14.0276V14.8721C14.0276 12.3916 17.1943 12.1805 17.1943 14.8721V19.3055H19.8332V13.9749C19.8332 9.80545 15.1359 9.96379 14.0276 12.0221V10.861Z"
        fill="white"
      />
    </svg>
  );
}
