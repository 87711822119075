import * as Cookies from "cookies-next";
import { OptionsType } from "cookies-next/lib/types";

// eslint-disable-next-line import/no-anonymous-default-export
export default new (class CookiesStorage {
  public readonly length = 0;

  protected generateOptions(options?: OptionsType): OptionsType {
    return { ...options };
  }

  async setItem(key: string, value: any, options?: OptionsType): Promise<void> {
    Cookies.setCookie(key, value, this.generateOptions(options));
  }

  getItem(key: string, options?: OptionsType): string | null {
    const data = Cookies.getCookie(key, this.generateOptions(options));
    if (!data) return null;
    return data.toString();
  }

  async removeItem(key: string, options?: OptionsType): Promise<void> {
    Cookies.removeCookies(key, this.generateOptions(options));
  }

  clear(options?: OptionsType): void {
    this.getCookieKey(this.generateOptions(options)).map((cookie) =>
      this.removeItem(cookie)
    );
  }

  key(index: number, options?: OptionsType): string | null {
    const cookies = this.getCookieKey(this.generateOptions(options)).find(
      (_, i) => index === i
    );
    return cookies || null;
  }

  private getCookieKey(options?: OptionsType): string[] {
    const cookies = Cookies.getCookies(this.generateOptions(options));
    return Object.keys(cookies);
  }
})();
