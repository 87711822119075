interface Props {
  size?: number;
  className?: string;
}

export default function Bullet(props: Props) {
  const { size = 18, className } = props;

  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_25489_3074)">
        <g filter="url(#filter0_d_25489_3074)">
          <circle cx="9" cy="9" r="3" fill="#464747" />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_25489_3074"
          x="0"
          y="0"
          width="18"
          height="18"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="6"
            operator="dilate"
            in="SourceAlpha"
            result="effect1_dropShadow_25489_3074"
          />
          <feOffset />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.180392 0 0 0 0 0.184314 0 0 0 0 0.184314 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_25489_3074"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_25489_3074"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_25489_3074">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
