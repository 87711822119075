interface Props {
  size?: number;
  className?: string;
  onClick?: VoidFunction;
}


export default function Eye(props: Props) {
  const { size = 30, className } = props;
  return (
    <svg width={size} className={className} onClick={props.onClick} height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M15 10.5C12.5147 10.5 10.5 12.5147 10.5 15C10.5 17.4853 12.5147 19.5 15 19.5C17.4853 19.5 19.5 17.4853 19.5 15C19.5 12.5147 17.4853 10.5 15 10.5ZM9.5 15C9.5 11.9624 11.9624 9.5 15 9.5C18.0376 9.5 20.5 11.9624 20.5 15C20.5 18.0376 18.0376 20.5 15 20.5C11.9624 20.5 9.5 18.0376 9.5 15Z" fill="currentColor"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M14.7452 7.5C8.29408 7.5 4.6753 11.9975 2.60593 15C4.6753 18.0025 8.29408 22.5 14.7452 22.5H15.2548C21.7059 22.5 25.3247 18.0025 27.3941 15C25.3247 11.9975 21.7059 7.5 15.2548 7.5H14.7452ZM14.1206 6.51321C7.30737 6.8032 3.57027 11.8006 1.58657 14.7189L1.39551 15L1.58657 15.2811C3.57027 18.1994 7.30737 23.1968 14.1206 23.4868C14.1574 23.4954 14.1958 23.5 14.2352 23.5H14.7452H15.2548H15.7648C15.8042 23.5 15.8426 23.4954 15.8794 23.4868C22.6926 23.1968 26.4297 18.1994 28.4134 15.2811L28.6045 15L28.4134 14.7189C26.4297 11.8006 22.6926 6.8032 15.8794 6.51321C15.8426 6.50457 15.8042 6.5 15.7648 6.5H15.2548H14.7452H14.2352C14.1958 6.5 14.1574 6.50457 14.1206 6.51321Z" fill="currentColor"/>
    </svg>
  );
}