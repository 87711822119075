import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {
  size?: string | number;
}

export default function SlidingPencil(props: Props) {
  const { size = 48 } = props;

  return (
    <svg width={size} height={size} {...props} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M29.9844 6C29.9844 6 24.3098 6.02492 18.5156 7.43359C15.6186 8.13793 12.6571 9.17228 10.2539 10.8906C7.85067 12.609 5.97656 15.2464 5.97656 18.5508C5.97656 21.6648 7.42246 23.687 8.46875 24.9102C9.51504 26.1333 10 26.4736 10 28C10 28.6475 9.3889 29.6962 8.38281 31.082C7.37673 32.4679 6 34.2049 6 36.6445C6 39.1806 7.79064 40.9522 9.27344 41.5312C10.7562 42.1103 12.1133 41.9961 12.1133 41.9961L11.8867 38.0039C11.8867 38.0039 11.2438 38.0067 10.7266 37.8047C10.2094 37.6027 10 37.6925 10 36.6445C10 35.8581 10.6233 34.7988 11.6172 33.4297C12.6111 32.0606 14 30.3985 14 28C14 25.2264 12.4725 23.4403 11.5078 22.3125C10.5431 21.1847 9.97656 20.6327 9.97656 18.5508C9.97656 16.6592 10.8561 15.3786 12.582 14.1445C14.3079 12.9105 16.8525 11.9545 19.4609 11.3203C24.6778 10.052 30.0156 10 30.0156 10L29.9844 6ZM38.7148 14C38.2726 14 37.8292 14.1698 37.4922 14.5078L35 17L41 23L43.4922 20.5078C44.1662 19.8318 44.1662 18.7365 43.4922 18.0625L39.9375 14.5078C39.5995 14.1698 39.1571 14 38.7148 14ZM32.1719 19.8281L16 36V42H22L38.1719 25.8281L32.1719 19.8281Z" fill="currentColor" />
    </svg>
  );
}