interface Props{
  size?: number;
}

export default function Outline(props: Props) {
  const { size = 30 } = props;

  return(
    <svg width={size} height={size} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width={size} height={size} fill="white" fillOpacity="0.01" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.72727 7.5C5.04947 7.5 4.5 8.04947 4.5 8.72727V24.2727C4.5 24.9505 5.04947 25.5 5.72727 25.5H21.2727C21.9505 25.5 22.5 24.9505 22.5 24.2727V14.5C22.5 14.2239 22.7239 14 23 14C23.2761 14 23.5 14.2239 23.5 14.5V24.2727C23.5 25.5028 22.5028 26.5 21.2727 26.5H5.72727C4.49718 26.5 3.5 25.5028 3.5 24.2727V8.72727C3.5 7.49718 4.49718 6.5 5.72727 6.5H14.3636C14.6398 6.5 14.8636 6.72386 14.8636 7C14.8636 7.27614 14.6398 7.5 14.3636 7.5H5.72727Z" fill="white" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M19.9676 6.5C20.1002 6.50003 20.2274 6.55275 20.3212 6.64656L22.3537 8.68038C22.5488 8.87567 22.5488 9.19219 22.3535 9.38741L13.9578 17.7814C13.8949 17.8443 13.8163 17.8893 13.7302 17.9117L11.576 18.4722C11.5759 18.4722 11.5757 18.4722 11.5756 18.4723C11.2806 18.5491 10.967 18.4638 10.7515 18.2482L11.1052 17.8947L10.7515 18.2482C10.5361 18.0326 10.451 17.7191 10.5277 17.4242C10.5277 17.4242 10.5277 17.4242 10.5277 17.4242C10.5277 17.4241 10.5278 17.424 10.5278 17.4239L11.0879 15.2682C11.1103 15.182 11.1553 15.1034 11.2183 15.0404L19.614 6.64641C19.7078 6.55264 19.835 6.49997 19.9676 6.5ZM12.0215 15.6514L11.5557 17.4441L13.3469 16.9781L21.293 9.03367L19.9674 7.70719L12.0215 15.6514ZM11.3235 17.5046C11.3236 17.5045 11.3237 17.5045 11.3238 17.5045L11.3235 17.5046Z" fill="white" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M21.0829 5.15869L22.5697 3.67193C23.3313 2.91029 24.5662 2.91029 25.3278 3.67193C26.0895 4.43358 26.0895 5.66845 25.3278 6.43009L23.8411 7.91685L21.0829 5.15869ZM22.4971 5.15869L23.8411 6.50263L24.6207 5.72298C24.9919 5.35186 24.9919 4.75016 24.6207 4.37904C24.2496 4.00792 23.6479 4.00792 23.2768 4.37904L22.4971 5.15869Z" fill="white" />
    </svg>
  );
}