import { PropsWithChildren, useEffect } from "react";
import useAmplitude from "@models/interactions/use-amplitude";
import { useSentry } from "@models/interactions";
import { ErrorBoundary } from "@sentry/react";
import { sdk, sdkv1 } from "@models/api";
import Cookies from "cookie-universal";
import { createStore, Provider, useStore } from "jotai";
import { useEffectOnce } from "react-use";
import { UserAtom, UserLoggedAtom } from "./atoms";
import { useRouter } from "next/router";
import { WriterInterface } from "@carglassgithub/memod-sdk";
import { UserInterface } from "@kanvas/client-js";

type InjectProps = {
  user: WriterInterface;
};

interface Props extends PropsWithChildren {
  user: WriterInterface;
  previousRoute: string;
}

export const ApplicationStore = createStore();

const cookies = Cookies();

const PAGE_CONTENT: Record<string, string> = {
  "/[user]/memos": "user",
  "/[user]/[...slug]": "memo",
};

function useInjectToken() {
  const injector = () => {
    const access = cookies.get("access_token");
    const refresh = cookies.get("refresh_token");

    if (!access || !refresh) return;

    sdk.tokenProvider.setToken(access.toString());
    sdk.tokenProvider.setRefreshToken(refresh.toString());

    sdkv1.tokenProvider.setToken(access.toString());
    sdkv1.tokenProvider.setRefreshToken(refresh.toString());
  };

  injector();
}

function StoreInjector({ user }: InjectProps) {
  const store = useStore();

  const injector = () => {
    if (user) {
      store.set(UserAtom, user as unknown as UserInterface);
      store.set(UserLoggedAtom, true);
    } else {
      store.set(UserAtom, null);
      store.set(UserLoggedAtom, false);
    }
  };

  useEffectOnce(() => {
    injector();
  });

  return null;
}

export function ApplicationProvider({ children, user, previousRoute }: Props) {
  useInjectToken();
  const isUserLoggedIn = sdk.tokenProvider.getToken().length > 0;
  const router = useRouter();
  const sentry = useSentry();
  const amplitude = useAmplitude();

  useEffect(() => {
    sentry.operations.initialize();
    amplitude.operations.initialize();
  }, [amplitude.operations, sentry.operations]);

  useEffect(() => {
    const sendData = {
      previous_path: `${window.location.hostname}${previousRoute}`,
      page_content_type: PAGE_CONTENT[router.pathname] ?? router.pathname,
      path: router.asPath,
      page_variant: "N/A",
      page_viewed_at: Date.now(),
      page_name: router.pathname,
    };

    if (isUserLoggedIn) {
      amplitude.operations.send("Page Viewed", sendData);
    } else {
      amplitude.operations.send("Unknown User Page Viewed", {
        ...sendData,
        unknown_user_id: amplitude.operations.getSessionId()?.toString()
          ?.substring(8, 12),
      });
    }
  }, [router.pathname]);

  return (
    <ErrorBoundary fallback={<p>Application failed</p>}>
      <Provider store={ApplicationStore}>
        <StoreInjector user={user} />
        {children}
      </Provider>
    </ErrorBoundary>
  );
}
