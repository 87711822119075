interface Props {
  size?: number;
  className?: string;
  fill?: string;
}

export default function Globe(props: Props) {
  const { size = 30, className, fill = "none" } = props;

  return (
    <svg width={size} className={className} height={size} viewBox="0 0 30 30" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <path d="M26.1665 14.9583C26.1665 21.1064 21.1683 26.0927 14.9998 26.0927C8.83131 26.0927 3.83313 21.1064 3.83313 14.9583C3.83313 8.8103 8.83131 3.82397 14.9998 3.82397C21.1683 3.82397 26.1665 8.8103 26.1665 14.9583Z" stroke="white" />
      <path d="M19.4994 14.9583C19.4994 18.1198 18.9477 20.9583 18.0755 22.9876C17.6391 24.0032 17.1322 24.7932 16.5949 25.322C16.0597 25.8487 15.5212 26.0927 14.9994 26.0927C14.4776 26.0927 13.9391 25.8487 13.4039 25.322C12.8665 24.7932 12.3597 24.0032 11.9232 22.9876C11.0511 20.9583 10.4994 18.1198 10.4994 14.9583C10.4994 11.7968 11.0511 8.9584 11.9232 6.92902C12.3597 5.91346 12.8665 5.12348 13.4039 4.59462C13.9391 4.06791 14.4776 3.82397 14.9994 3.82397C15.5212 3.82397 16.0597 4.06791 16.5949 4.59462C17.1322 5.12348 17.6391 5.91346 18.0755 6.92902C18.9477 8.9584 19.4994 11.7968 19.4994 14.9583Z" stroke="white" />
      <path d="M19.4994 14.9583C19.4994 18.1198 18.9477 20.9583 18.0755 22.9876C17.6391 24.0032 17.1322 24.7932 16.5949 25.322C16.0597 25.8487 15.5212 26.0927 14.9994 26.0927C14.4776 26.0927 13.9391 25.8487 13.4039 25.322C12.8665 24.7932 12.3597 24.0032 11.9232 22.9876C11.0511 20.9583 10.4994 18.1198 10.4994 14.9583C10.4994 11.7968 11.0511 8.9584 11.9232 6.92902C12.3597 5.91346 12.8665 5.12348 13.4039 4.59462C13.9391 4.06791 14.4776 3.82397 14.9994 3.82397C15.5212 3.82397 16.0597 4.06791 16.5949 4.59462C17.1322 5.12348 17.6391 5.91346 18.0755 6.92902C18.9477 8.9584 19.4994 11.7968 19.4994 14.9583Z" stroke="white" />
    </svg>
  );
}