import cookiesStorage from "@models/interactions/cookies-storage";
import MemodSDK from "@carglassgithub/memod-sdk";

export const sdk = new MemodSDK({
	appKey: "",
	baseUrl: process.env.NEXT_PUBLIC_API as string,
	storage: cookiesStorage,
});

export const sdkv1 = new MemodSDK({
	appKey: "",
	baseUrl: process.env.NEXT_PUBLIC_API_V1 as string,
	storage: cookiesStorage,
});
