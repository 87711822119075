import classNames from "classnames";
interface Props {
  className?: string;
}

export default function Done(props: Props) {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(props.className)}
    >
      <rect
        x="13.332"
        y="13.3359"
        width="73.3333"
        height="73.3333"
        rx="36.6667"
        fill="#787880"
        fillOpacity="0.36"
      />
      <path
        d="M30 48.96L42.96 61.92L68.88 36"
        stroke="#05A88F"
        strokeWidth="4"
        strokeLinecap="round"
      />
    </svg>
  );
}
