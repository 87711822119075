import amplitude from "amplitude-js";
import { useRef } from "react";
import { translate } from "src/locales";

const AMPLITUDE_KEY = process.env.NEXT_PUBLIC_AMPLITUDE_KEY;

export default function useAmplitude() {
  const current = amplitude.getInstance();

  const initialize = () => {
    if (!AMPLITUDE_KEY) {
      console.error(translate("amplitude.key-missing"));
      return;
    }

    current.init(AMPLITUDE_KEY);
  };

  const setUserDevice = (deviceId: string) => current.setDeviceId(deviceId);
  const setUserId = (userId: string | null) => current.setUserId(userId);
  const setProperties = (properties: any) =>
    current.setUserProperties(properties);
  const send = (eventType: string, eventProperties: any) =>
    current.logEvent(eventType, eventProperties);
  const getSessionId = () => current.getSessionId();
  const getUserId = () => current.getUserId();

  return {
    operations: {
      initialize,
      setUserDevice,
      setUserId,
      setProperties,
      send,
      getSessionId,
      getUserId,
    },
  };
}
