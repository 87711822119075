interface Props {
  className?: string;
  size?: number;
  height?: number;
}

export default function Down(props: Props) {
  const { className, size = 18, height = 18 } = props;
  return (
    <svg width={size} height={height} className={className} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 6.00056L9.08023 12.1738L14.5294 6.00056" stroke="#0F0F10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}