interface Props {
  className?: string;
  size?: string;
}

export default function NotificationCheck(props: Props) {
  const { size = 30, className } = props;
  return(
    <svg width={size} height={size} className={className} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="15" cy="15" r="15" fill="white" />
      <path d="M10.457 15.0852L13.4856 18.1138L19.5427 12.0566" stroke="#3A3A3C" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
}