/* eslint-disable import/no-anonymous-default-export */

export default {
  title: "Memo’d: Ideas made simple",
  backToHome: "Go to Home Page",
  serverError: "An error {{code}} occurred on server.",
  clientError: "An error occurred on client.",
  error404: {
    title: "Uh oh!",
    description:
      "Oops! The page you were looking for doesn’t exist. Let’s take you back to the home page.",
  },
  beta: "Beta",
  base: {
    deleteBullet: "Are you sure you want to delete this bullet?",
    share: "Share",
    "min-read": "{{time}} min read",
    "mins-read": "{{time}} mins read",
    "read-next": "Read Next",
    following: "Following",
    "for-you": "For You",
    "your-topics": "Your topics",
    library: "Library",
    "your-library": "Your Library",
    public: "Public",
    private: "Private",
    bullets: "Bullets",
    inspiration: "Inspiration in 10 bullet points",
    followers: "Followers",
    likes: "Likes",
    mentions: "Mentions",
    follow: "Follow",
    notable: "Notable",
    save: "Save",
    "Block-user": "Block user",
    "block-user": "Block {{query}}",
    "block-advisor":
      "This user won’t be able to follow you and you will not see notifications and content from {{query}}",
    recent: "Recent",
    block: "Block",
    blocked: "Blocked",
    notifications: "Notifications",
    "all-notifications": "All Notifications",
    inbox: {
      title: "{{type}} Notifications",
      links: {
        memo: "See Memo",
        comment: "See Comment",
        collection: "See Collection",
      },
    },
    unBlock: "Unblock",
    "publish-message": "Published! Your Collection is now live on Memo’d",
    "status-memo": "Your memo {{query}} was {{status}} successfully",
    "status-collection": "Your collection {{query}} was edited successfully",
    saveBoard: "Save to collection",
    saved: "Saved",
    "get-started": "Get Started",
    published: "Published",
    "create-memo": "Create Memo",
    "show-all": "Show All",
    "share-know": "Share your knowledge...",
    "memo-banner-title": "Memo’d: Big ideas, simplified",
    "memo-banner-tagline": "Share bite-sized knowledge",
    "amount-comments": "{{amount}} comments",
    saves: "{{amount}} saves",
    memos: "{{amount}} Memos",
    follows: "{{amount}} Follows",
    "quick-start": "Quick Start Guide",
    "collection-switch": "Collection Switch to ",
    "collection-state": ["Private", "Public"],
    "item-status": "made {{status}}",
    read: "{{amount}} min read",
    like: "Like",
    topics: "Topics",
    "choose-image": "Choose Image",
    create: "Create",
    "create-new": "Create New",
    memo: "Memo",
    Help: "Help",
    Memos: "Memos",
    "paywall-banner-1": "Still not convinced? ",
    "paywall-banner":
      "Try our free {{link}} for the same unlimited access without having to register.",
    creators: "Creators",
    "create-account": [
      "Create Account",
      "Follow People and Topics",
      "Fill out your profile",
      "Click on “Create”... and you’re off!",
    ],
    "create-memo-message":
      "Click “Create Memo” and share your knowledge with the world!",
    collections: "Collections",
    "search-placeholder": "Search for Memos, Creators, Topics, Collections",
    "no-search-query": "Your search for “{{query}}” did not have any matches.",
    "no-results-found":
      "Try searching for another title, topic, writer or keyword. Alternatively, explore the recommended content below!",
    add: "Add",
    "profile-state": "No published {{entity}}… yet!",
    "empty-following": "No following any {{entity}}… yet!",
    "empty-followers": "No followers here… yet!",
    remove: "Remove",
    "you-can-title": ["Repurpose", "Publish", "Share", "Summarize"],
    "you-can-description": [
      "Repurpose your existing content into succinct Memos",
      "Publish new or original ideas without a big time commitment",
      "Share expertise or insights unique to your career or industry",
      "Summarize books, podcasts, talks, and articles while connecting with an audience hungry for important ideas",
    ],
    "added-collection": "Added to Collection",
    "removed-collection": "Removed from Collection",
    collection: "Collection",
    "collection-public": ["Private Collection", "Public Collection"],
    "collection-empty": "Your Collection is empty.",
    "collection-message":
      "Add Memos using the button below, or directly from a Memo card.",
    "create-collection": "Create Collection",
    "add-memo": "Add Memos",
    edit: "Edit",
    "edit-order": "Edit Order",
    "edit-profile": "Edit Profile",
    "edit-memo": "Edit Memo",
    "delete-memo": "Delete Memo",
    "make-private": ["Make Public", "Make Private"],
    dashboard: "Dashboard",
    "dashboard-card-state": ["Private", "Public"],
    "memo-card-state": ["Draft", "Published  "],
    "add-colletion": "Add an optional description",
    "edit-list": "Edit List",
    "delete-collection": "Delete Collection",
    "delete-collection-message":
      "Are you sure you want to delete this Collection?",
    comments: "Comments",
    "no-results": "No results found",
    "try-searching": "Try searching for another keyword",
    source: "Source",
    "report-memo": "Report Memo",
    "save-board": "Save to new collection",
    "write-comment": "Add a comment",
    post: "Post",
    reply: "Reply",
    submit: "Submit",
    done: "Done",
    about: "About",
    explorer: "Explorer",
    "start-creating": "Start Creating!",
    report: "Report",
    ccp: "Content Creation Policies",
    "collection-title": "Collection Title",
    "collection-description": "Add an optional description",
    search: "Search for memos",
    "search-collection": "Search for Memos in this Collection",
    cancel: "Cancel",
    close: "Close",
    error: "Error",
    "error-message":
      "You must have at least one free Memo to have locked Memos in your Collection",
    "save-changes": "Save Changes",
    "saved-changes": "Your changes have been saved",
    "share-to": "Share to",
    "edit-details": "Edit Details",
    publish: "Publish",
    unsuscribed: "You've been unsubscribed!",
    "unsuscribed-message":
      "If you'd still like to receive personalized notifications, you can toggle them in Settings.",
    "go-settings": "Go to Settings",
    delete: "Delete",
    settings: "Settings",
    profile: "Profile",
    confirmation: "Confirmation",
    "delete-memo-message": "Are you sure you want to delete this Memo?",
    "help-center": "Help Center",
    "contact-us": "Contact Us",
    "get-in-touch": "Get in Touch, we’d love to hear from you.",
    "reach-email": "Reach us by email",
    "type-question": "Type Your Question",
    "no-search-term":
      "Type your question in the box above to search the Help Center.",
    "help-sedcription":
      "For more detailed information and guidance on producing high-impact Memos, read our ",
    "you-can": "You can:",
    "what-creators": "What our creators say",
    "testimonials-subtitle": "Can I start writing Memos immediately?",
    "testimonials-memos":
      "Memos help you organize your thoughts, hone your writing skills, and gain a new audience for your ideas or insights.",
    yes: "Yes!",
    "sign-out": "Sign out",
    send: "Send",
    confirm: "Confirm",
    "copy-link": "Copy Link",
    "dont-have-account": "Don't have an account?",
    "already-account": "Already have an account?",
    "forgot-password": "Forgot your password?",
    "your-email": "Your email",
    "current-password": "Current password",
    "new-password": "New password",
    "your-password": "Your password",
    "repeat-password": "Repeat your password",
    "confirm-password": "Confirm Password",
    "enter-your-email": "Enter your email",
    "enter-your-password": "Enter your password",
    "conditions-message": "By clicking on Sign up, you are agreeing to our",
    "terms-condition": "Terms and conditions",
    "privacy-policy": "Privacy policy",
    "social-sign-in": "Social Sign In",
    "not-user-found": "User Not Found",
    "please-try-again": "Please, try again later!",
    "error-social-media": "Error authenticating with social media",
    "unlock-memo": "Unlock Memo",
    "option-remove": "Remove from this Collection",
    "option-copy": "Copy Link",
    "search-results": "Search Results",
    "help-search-results": "{{amount}} search results for “{{term}}”",
    "learn-more": "Learn more",
    and: "and",
    see: "See",
    "added-to-collection": "Added to Collection",
    "removed-from-collection": "Removed from Collection",
    next: "Next",
    back: "Back",
    showMore: "Show More",
    "delete-bullet": "Are you sure you want to delete this highlight?",
    "sign-up": {
      base: "Sign up",
      apple: "Sign up with Apple",
      facebook: "Sign up with Facebook",
      twitter: "Sign up with Twitter",
      email: "Sign up with Email",
    },
    "sign-in": {
      base: "Sign in",
      apple: "Sign in with Apple",
      facebook: "Sign in with Facebook",
      twitter: "Sign in with Twitter",
      loggin: "Log in",
    },
    payment: {
      "try-again": "Try again",
      failed: "Payment failed",
      message: "We weren’t able to process your payment. Please try again",
    },
    navbar: {
      create: "Create",
      home: "Home",
      dashboard: "Dashboard",
    },
    username: "username",
    onboarding: {
      step1: {
        username: "Let’s set up your {{username}}",
      },
      step2: {
        topics: "Follow at least {{amount}} to get started!",
        amount: "{{amount}} Topics",
      },
    },
    agree: "I agree",
    "dont-show-again": "Don’t show this again",
  },
  library: {
    "empty-states": {
      published: "You haven't created any {{type}}... yet!",
      drafts: "Your private{{state}} {{type}} will appear here",
      saved: "You haven't saved any {{type}}... yet!",
      bullets: "Save any bullet in a Memo to read it here later",
      "saved-collections": "Follow your favorite Collections to save them here",
    },
    "unpublished-memo": "Unpublished Memo",
    "private-memo": "Private Memo",
    draft: "draft",
    saved: "Added to Library",
    remove: "Remove from Library",
    removed: "Removed from Library",
    memos: {
      "saved-collection": "Saved to Collection",
      "removed-collection": "Removed from Collection",
    },
    collections: {
      saved: "Added to your Saved Collections",
      removed: "Removed from your Saved Collections",
    },
    search: {
      title: "Search your Library",
      description: "Find everything you’ve saved, followed or created",
    },
  },
  errors: {
    form: {
      "text-input": {
        label: "You must specify either a Label (ReactNode) or label (string).",
      },
      "site-url": "Must be a valid link, e.g. https://memod.com",
      "first-name": "The firstname field is required.",
      "last-name": "The lastname field is required.",
    },
    notification: {
      "create-collection": "Published! Your Collection is now live on Memo’d",
    },
  },
  "reset-password": {
    "forgot-password": "Forgot your password?",
    "forgot-message":
      "Let us help you out. Enter your email address below and we’ll send you instructions to reset your password.",
    "your-email": "Your email",
    "reset-password": "Reset password?",
    "reset-message": "Type your new password to log into your account.",
    "password-updated": "Password Updated!",
    "password-updated-message":
      "Now you can type in the new password to log into your account.",
    "password-sent": "Password Reset Sent",
    "password-sent-message":
      "A password reset link has been sent to your email. If you haven't received it within a few moments, please check your spam folder.",
  },
  composer: {
    new: {
      title: "New Memo from Scratch",
      subtitle: "Use our composer to write your Memo one bullet point at a time.",
    },
    ai: {
      new: {
        title: "Create content with AI",
        subtitle: "Pick an idea and AI will craft a Memo about it",
        "limit-reached": "You've reached the daily limit for idea generation",
      },
      ideas: {
        "generate-content": "AI Content Creator",
        "generate-ideas": "Generate Ideas",
        "explore-subject": "Make any subject easy to understand in a clear 10-point Memo",
        "select-topic": "No ideas? Simply pick a topic:",
        "enter-topic": "Type your idea here",
        "topic-example": '(e.g. "Philosophy for beginners")',
        "brainstorming": "Brainstorming ideas... 💡",
      },
      selector: {
        "select-idea": "Select idea",
        "writing-style": "Choose a writing style",
        "styles": {
          "academicExpert": "Academic Expert",
          "funnyStoryteller": "Fun Storyteller",
          "viralInfluencer": "Viral Influencer",
        },
        "generate-memo": "Generate Memo",
        processing: [
          "Summoning creative inspiration... 🙏",
          "Crafting your masterpiece... ✍️",
          "Sprinkling magic on your words... 🪄",
        ],
        discard: {
          title: "Discard ideas?",
          content: "You will not be able to recover them",
        },
      },
      memo: {
        "generate-again": "Generate Again",
        "generate-warning": "Heads Up: You can only regenerate this content once",
        discard: {
          title: "Discard this memo?",
          content: "You will not be able to recover it",
        },
        image: {
          loading: "Searching for the perfect image... 🖼️",
          error: "Found a speed bump while searching, please try again!",
        },
      },
      expended: {
        "generate-content": "Generate Content",
        "reached-limit": "You've reached the daily limit for idea generation",
        "suggestion": {
          "part-1": "Write a Memo from scratch",
          "part-2": "or generate more ideas tomorrow",
        },
      },
      error: {
        title: "Oops! Something went wrong",
        content: "Please try again",
      },
      feedback: {
        title: "Give Feedback",
        subtitle: "What do you think of the AI writing?",
        reason: "What are the main reasons for your rating?",
        thanks: "Thanks for your feedback!",
      },
    },
  },
  continue: "Continue",
  discard: "Discard",
  form: {
    "password-not-match":
      "The confirm password field confirmation does not match",
    "password-eight-password": "Password must be at least 8 characters long",
    required: {
      email: "The email field is required",
      password: "The password field is required",
      "current-password": "The current password field is required",
      "confirmation-password": "The confirm password field is required.",
      username: "The username is required.",
    },
    "username-characters":
      "Only letters, numbers, underscore, and periods are allowed.",
    "username-length": "Username must be between 4 and 20 characters",
  },
  report: {
    "done-title": "Thanks for reporting",
    "done-message":
      "We’ll review your report and take action if there is a violation to our Content Creation Policies",
    "tell-us": "Tell us why",
    "ccp-message": "Not sure if something is breaking the rules? Read the",
    "copyright-issue":
      "Provide the original source of the plagiarized content or copyright issue",
    "other-reason": "Explain why this Memo contains inappropriate content",
    "other-reason-title": "Other reason",
    "plagiarized-title": "Contains plagiarized content",
    "vulgar-title": "It contains vulgar or obscene content",
    "hateful-title": "It promotes hateful or harmful ideas",
    "other-reason-title-comment":
      "Show us the uncited source of this Memo has inappropriate content",
  },
  sentry: {
    "dsn-missing": "Sentry DNS Env variable is not define",
  },
  paywall: {
    description:
      "Want to get a feel for Memo'd or read other Memos by this creator first?",
    explore: "Explore",
    "for-free": "for free",
    price: "${{amount}} USD",
    question: "What are locked Memos",
    suggestion: "our suggestions on the right",
    title: "Support this creator and gain access to exclusive content",
    unlock: "Unlock",
  },
  amplitude: {
    "key-missing": "Amplitude key env variable is not define",
  },
  edit: {
    "pick-username": "Pick your username",
    url: "URL",
    "url-placeholder": "Add a link to your personal website, blog, etc.",
    bio: "Bio",
    "what-your": "Pick your username",
    "bio-placeholder":
      "Share your interests and experience. What topics are you passionate about? What skills inspire you?",
    "required-name": "The firstname field is required.",
    "required-username":
      "The username is required. Only letters, numbers, underscored and periods are allowed.",
    "full-name": "What is your full name?",
    email: "Email",
    first: "First",
    last: "Last",
    "profile-edited": "Your profile has been updated",
  },
  settings: {
    "delete-account": "Delete Account",
    "delete-account-header":
      "Time to say goodbye? Simply click Delete Account.",
    "delete-account-message":
      "Your account will be flagged as deleted. You can reverse this process by simply logging in at any point ",
    "delete-account-message-2":
      "in the next 30 days. Otherwise your account will be permanently deleted from our database.",
    "change-password": {
      menu: "Password",
      title: "Change Password",
      confirmation: "Your password has been updated",
    },
    "content-monetization": {
      menu: "Content Monetization",
      title: "Creator Payment",
      terms: {
        rewards:
          "Our content monetization tools allow creators to receive rewards for a job well done.",
        stripe:
          "By connecting your profile with Stripe, you can ‘lock’ individual Memos behind a paywall and set a price for readers to gain access.",
        tipping:
          "By clicking ‘Allow Tipping’, you can also add links to select third-party payment services (PayPal, Patreon, and Buy Me a Coffee) to your Memo’d profile.",
        features:
          "You can read more about both of these features in our {{terms}} or in the {{help}}.",
        monetization: "Content Monetization terms",
        "help-center": "Memo’d Help Center",
        creators:
          "This is our way of making it easier to support creators sharing knowledge and inspiration on Memo’d.",
        agreement: {
          content: "{{part1}}{{part2}}",
          part1: "By clicking on “I agree” you are agreeing to our",
        },
      },
      stripe: {
        title: "Connect a Stripe account",
        connect: "Connect Stripe",
        disconnect: "Disconnect Stripe",
      },
      tipping: {
        title: "Tipping",
        options: "Manage payment options",
        tips: "Manage Tips",
        allow: "Allow Tipping",
        "allow-note": "Allow others to directly send you money through tips",
        activated: "Allow Tipping is activated",
        deactivated: "Allow Tipping is off",
        saved: "Payment options have been saved",
        description:
          "People can send tips via the profile you're linking to here. This service is processed by the payment provider itself. Memo'd will not receive any portion of the tip or related processing fees.",
      },
    },
    "blocked-accounts": "Blocked Accounts",
    "no-users": "No blocked users",
    account: "Account",
    "edit-profile": "Edit Profile",
    "social-conections": "Social Conections",
    "email-notifications": "Email Notifications",
    logout: "Logout",
  },
  landing: {
    name: "Memo’d",
    title: "Competition Memo’d",
    keywords: [
      "Competition Memo’d",
      "Memo’d",
      "Prizes with Memo’d",
      "Write to win",
    ],
    prizes: "{{prizes}}",
    ellipse:
      "Recommended by Reedsy as one of the best writing contests of 2023  ",
    description:
      "Participate in the Memo’d Monthly Prize Competition and stand a chance to win $750 or $500. Memo’d is a note-sharing app where you can find inspirations and ideas shared by our community of creators.. Join now to get a chance to win big.",
    "header-prizes": "Prizes",
    "header-tips": "Tips",
    "header-how": "How to Enter",
    "write-title": "Write to win contest",
    "write-content": "Write a 10-point Memo and win up to $2,000",
    "write-content-":
      "We at Memo’d are helping writers increase their influence and cashflow.",
    "prizes-title": "Prizes",
    "prizes-title-750": "$750",
    "prizes-title-500": "$500",
    "prizes-content-memo": "For Most Read Memo",
    "prizes-content-engage": [
      "For Most Engaging Memo",
      "(most Follows, Likes & Comments)",
    ],
    "prizes-content-editor": "Editor's Pick",
    "tips-title": "What should “Write to Win” Memos be about?",
    "tips-content":
      "It’s up to you! Memo’d is a short-form learning platform, so valuable insights on work, life, philosophy, society, history, psychology, politics, and the world is what we’re about.",
    "tips-footer": [
      "Write about philosophy like ",
      "@Jashdholani",
      ", Science like ",
      "@ScienceSimplified",
      ", Money like ",
      "@TomBBMoney",
      ", Health like ",
      " @workingonwellness",
      " - the options are endless. There are lots of inspiring creators on Memo'd!",
    ],
    "tips-original": ["Original idea ", "or point of view"],
    "tips-insig": ["Insightful ", "summary ", "of a book, article, film etc"],
    "tips-unique": ["Unique experience or expertise e.g.", ` “What I learned”`],
    "tips-helpful": [
      "We have lots of helpful tips here:",
      "How to Write a Great Memo",
      "Get Started on Memo'd",
      "Check out some ",
      "guide Memos here",
    ],
    "share-title": "Share your Memo",
    "share-content":
      "The key to views, engagement, and winning is to share your Memo:",
    "share-list": [
      "On social media",
      "With colleagues, family and friends",
      "Postings in forums & communities",
    ],
    "share-footer": [
      "You'll find tips on sharing here: ",
      "Sharing your Memos.",
    ],
    "how-title": "How to Enter:",
    "how-content": `The FREE competition begins on the 1st of the month and closes on the last day of the month. Winners will be announced on the “Notable” page of Memo'd 7 days after the contest ends and all participants will be notified via email.`,
    "how-card-create": "Create a FREE account on Memo’d",
    "how-card-write": "Write a Memo",
    "how-card-write-content": [
      "Tips ",
      " and ",
      "here",
      " on getting started ",
    ],
    "how-card-ideas": "Ideas for Memos",
    "how-card-add": "Add topic",
    "how-card-add2": "“MemodWriteToWin”",
    "how-card-add-content":
      "Add this as one of your 4 topics in the Publishing panel ",
    "how-card-publish": "Publish your Memo!",
    "how-card-publish-content": "All competition entries must abide by the",
    "how-card-publish-terms": "terms and conditions. ",
    "email-title":
      "Subscribe below for winner notifications and contest reminders",
    "email-signup": "Submit",
    "email-success": "Email submitted successfully.",
    "email-fail": "Email already registered",
    "email-question": "Questions? ",
    "email-email": "Email us!",
    "email-memo": "hello@memod.com",
    "email-place-holder": "Your email address",
    signup: "Sign Up",
    "footer-legal": "Legal",
    "footer-navigate": "Navigate",
    "footer-write": "Write to win",
    "footer-suscribe": "Suscribe",
    "footer-terms": "Terms of Service",
    "footer-privacy": "Privacy Policy",
    "footer-cookies": "Cookies Opt/Out",
    "footer-copy": "Copyright Policy",
    "footer-explorer": "Explorer",
    "footer-careers": "Careers",
    "footer-about": "About",
    "footer-help": "Help Center",
    "footer-contact": "Contact Us",
    "footer-write-to": "Write to Win A contest",
    "footer-terms-condintion": "Terms & Conditions",
    "footer-follow": "Follow us",
    "footer-spam":
      "No spam, notifications only about new products and updates. You can always unsubscribe.",
    "footer-memod": "Memo’d. All rights reserved",
    "footer-memod-2": "Copyright © 2023 Memo’d",
    "footer-download": "Download App",
    "footer-how-to": "How to Enter",
    "footer-What": "What to write about",
    "footer-join": "Join Memo’d",
    "footer-about-memo": "About Memo’d",
  },
  contest: {
    button: "Start now",
    title: "MEMO’D WRITE TO WIN CONTEST TERMS OF ENTRY",
    "title-article": [
      `WELCOME TO MEMO’D’S WRITE TO WIN CONTEST (`,
      "“WRITE TO WIN CONTEST”",
      " OR",
      " “CONTEST”",
      `)!`,
    ],
    article: [
      "PLEASE READ THE FOLLOWING TERMS AND CONDITIONS (",
      "“WRITE TO WIN CONTEST TERMS”",
      ") CAREFULLY. BY PARTICIPATING IN THE WRITE TO WIN CONTEST, (ANY PARTICIPATING INDIVIDUAL OR ENTITY IN THE WRITE TO WIN CONTEST IS REFERRED TO AS ",
      "“YOU, ”",
      " “YOUR, ”",
      " OR ",
      " “CREATOR”",
      ") HEREBY ACCEPT AND AGREE TO BE LEGALLY BOUND BY THESE TERMS AND ALL TERMS INCORPORATED HEREIN BY REFERENCE. IT IS YOUR RESPONSIBILITY TO READ THE TERMS BEFORE PROCEEDING TO PARTICIPATE IN THE WRITE TO WIN CONTEST, INCLUDING BUT NOT LIMITED TO ALL FEATURES, SITES, AND CONTENT.",
    ],
    "sections-numbers": [
      "1.",
      "2.",
      "3.",
      "4.",
      "5.",
      "6.",
      "7.",
      "8.",
      "9.",
      "10.",
      "11.",
      "12.",
      "13.",
      "14.",
      "15.",
    ],
    "sections-subtitle": [
      "ACCEPTANCE OF TERMS",
      "ELIGIBILITY",
      "WRITE TO WIN CONTEST",
      "PAYMENT",
      "INTELLECTUAL PROPERTY",
      "TERMINATION",
      "PARTIES’ RELATIONSHIP",
      "COMMUNICATIONS",
      "CONFIDENTIALITY",
      "REPRESENTATIONS AND WARRANTIES",
      "ACCOUNT SUSPENSION/TERMINATION; FORFEITURE; WITHDRAWAL",
      "DISCLAIMER",
      "LIMITATION OF LIABILITY",
      "ASSIGNMENT",
      "GENERAL",
    ],
    "constent-1": [
      "The following Write to Win Contest Terms govern Your participation in the Write to Win Contest on the Memo’d Platform. The Write to Win Contest Terms are subject to, and will also include, the Memo’d Terms of Service, Privacy Policy, and all other Memo’d policies and guidelines, each as may be updated or amended from time to time (together, the ",
      "“Policies”",
      "). In the case of any inconsistency between these Write to Win Contest Terms and the Policies, insofar as they relate to the Write to Win Contest, these Write to Win Contest Terms will prevail. Upon expiration or termination of the Write to Win Contest or Creator’s participation in the Write to Win Contest, the Policies will remain in effect and continue to apply to Your use of the Platform. Any capitalized terms used but not defined herein has the respective meanings given to them in the Policies.",
    ],
    "constent-2-1": [
      "Creators who want to participate in the Write to Win Contest will have a chance to win an award payment and access to certain Write to Win Contest Benefits (as described below).",
      "There is no cost or fee to enter. The duration of the contest is described in the publicly posted Contest instructions.",
    ],
    "constent-2-2":
      "You must have an active Memo'd account in good standing and in compliance with Memo'd's Policies.",
    "constent-2-3":
      "You must have a valid Paypal Account able to receive payments originating from the United States and comply with any necessary documentation requests (including, without limitation, any tax reporting documents required by Memo'd or the payment account provider).",
    "constent-2-4":
      "By participating in the Program, you agree to be bound by these Terms as a legal agreement. You must be at least 13 years old to participate in the Contest.",
    "constent-2-5":
      "If You are under 18 years old, You represent that You have Your parent or guardian’s permission to participate in the Contest. Please have them read these Write to Win Contest Terms with You. If You are a parent or guardian of a Creator under the age of 18, by allowing your child to participate in the Write to Win Contest, you are subject to these Write to Win Contest Terms and responsible for your child’s activity in the Write to Win Contest.",
    "constent-2-6":
      "If You are using the Write to Win Contest on behalf of a company or organization, You represent that You have the authority to act on behalf of that entity, and that such entity accepts these Write to Win Contest Terms.",
    "constent-3-1": [
      "You will write a Memo based on the provided topic or prompt. You will publish the finished Memo on our Platforms according to the directions specified in the prompt, which includes appropriately tagging Your Memos per publicly posted Contest instructions.",
      "Multiple entries of different Memos are permitted, unless it is otherwise specified in the publicly posted Contest instructions. For the avoidance of doubt, You may not enter the same Memo multiple times using multiple or one account. Only new Memos published for the first time in the month of the Contest are eligible for award. Memos previously published or republished are ineligible.",
    ],
    "constent-3-1-b": [
      " Awards will be given for the Memo based on the categories described in the publicly posted Contest instructions. Memo’d reserves the right to confirm read counts were not manipulated using IP address verification or other methods deemed reliable by Memo’d.",
      " Creators may use social media or other similar means (and are subject to the terms of those third-party services) to promote their Memo and encourage their audience to read, follow, like, and comment on their Memo. ",
    ],
    "constent-3-2":
      "Creators will receive the following Write to Win Contest Benefits:",
    "constent-3-3": [
      "The available prizes and corresponding categories that are described in the publicly posted Contest instructions",
    ],
    "constent-3-4":
      "Create Memos under Your name, or anonymously if You wish, and share insightful content with Memo’d’s fast growing community of curious readers. ",
    "constent-3-5": "Share and promote Your Memos.",
    "constent-3-6":
      "For any applicable cash or gift card prizes, you may keep 100% of the payment award from Your winnings should You win (less any Additional Fees described below).",
    "constent-3-7":
      "Start building a sticky audience and meeting other like-minded Creators / Users.",
    "constent-3-8": [
      "Memo’d reserves the right in its sole discretion to modify, terminate, establish, control, and limit any aspect of the Write to Win Contest from time to time, and without any other notice to You.",
      " Any such change, update, or modification will be effective immediately upon posting. Please review these Write to Win Contest Terms periodically to ensure that you continue to agree with all of the terms. If You continue to participate in the Write to Win Contest after those changes, updates, or modifications are in effect, You agree to the revised terms.",
    ],
    "constent-4-1": [
      "All winners are officially notified by the email address associated with the Memo’d account used to enter the Write to Win Contest within seven (7) days from the close of the Contest.",
      " If a winner does not respond to the notification within five (5) business days and/or fails to confirm a valid payment setup, another winner will be chosen. Failure to respond within the time frame listed herein shall mean that any award the provisional winner may be otherwise entitled to is void and forfeited. ",
    ],
    "constent-4-2": [
      "If You are notified as a winner, Memo’d will pay You within forty-five (45) days from notice of Your award.  Only winning Memos by eligible Users may be considered for any award.",
      "Any general Memos, not properly tagged for Entry into the Write to Win Contest published by a Write to Win Contest Creator, are not considered Write to Win Contest Memos and are ineligible for any award.",
    ],
    "constent-4-3": [
      "Memo’d uses one or more third party payment processors (each, a ",
      "“Payment Processor”",
      ") authorized by Memo’d to collect, process, and remit payments on Memo’d’s behalf. You may be subject to the terms, conditions, privacy policies, and personal information collection practices of the Payment Processor, in addition to these Write to Win Contest Terms.",
    ],
    "constent-4-4":
      "You are responsible for any third-party fees, foreign exchange gains or losses, transaction fees, bank fees, taxes, duties, or charges incurred through services provided to You by Your payment account provider or Payment Processor.",
    "constent-4-5":
      "You may be liable to pay taxes to Your country of residence on Your payment award from the Write to Win Contest. Check with Your local tax authorities for detailed guidance. Memo’d will collect tax identification information and will charge and withhold the applicable taxes if required by law. Notwithstanding the foregoing, You will be solely responsible for bearing, reporting, and remitting all income or withholding taxes and other government charges which may be due as a result of the payments received.",
    "constent-5-1": [
      "The Write to Win Contest and Platforms (including all Memo’d content included or accessible within) are the sole property of Memo’d and are protected by intellectual property rights. You will be the sole and exclusive owner of all intellectual property rights in any Memo that You create in connection with the Write to Win Contest (",
      "“Write to Win Content”",
      "). You understand and agree that from time to time Memo’d may use Your Write to Win Content per our Terms of Service.",
    ],
    "constent-5-2":
      "Memo’d hereby grants to You a non-exclusive, limited license during the term of this Agreement, under all intellectual property rights owned or controlled by Memo’d, solely to the extent required for You to participate in the Write to Win Contest in accordance with these Write to Win Contest Terms. With the exception of the foregoing limited license, no right, title or interest in or to any intellectual property rights of Memo’d are granted to You.",
    "constent-5-3": [
      "You grant to Memo’d the right to use Your name, voice, image, likeness, and biographical information You provide to Memo’d (collectively,",
      " “Attributes”",
      ") in connection with the distribution, exhibition, marketing, and other use of Write to Win Content Content. You release Memo’d, its affiliates, and their respective assigns, licensees, and successors from any claims that may arise regarding the use of Your Attributes, including any claims of defamation, invasion of privacy, or infringement of moral rights, rights of publicity, or copyright.",
    ],
    "constent-6":
      "The term of this agreement shall begin upon Your Entry to participate in the Write to Win Contest. This Agreement will remain in effect, unless terminated as set forth below.",
    "constent-7":
      "Your participation in the Write to Win Contest does not imply any agency, representation, sponsorship, or endorsement between Memo’d and You.  Your participation in the Write to Win Contest is voluntary and at will.  Nothing in Your participation in the Write to Win Contest or this Agreement should be construed to create a partnership, joint venture, or employer-employee relationship. ",
    "constent-8":
      "Subject to the Privacy Policy, you may receive promotional email communications regarding the Services from Memo’d from time to time. If You would like to unsubscribe from these promotional emails, find and click the unsubscribe button at the bottom of the promotional emails.",
    "constent-9": [
      "Any information about Memo’d, its affiliates, or its products, including the Platform and the Services, that is made available to You through the Contest, including but not limited to this Agreement, that is not otherwise publicly available is Memo’d’s confidential information(",
      "“Confidential Information”",
      "). You agree not to disclose any Confidential Information to any third party under any circumstance, unless previously approved in writing by Memo’d.",
    ],
    "constent-10":
      "You are solely responsible for your Posted Content and the consequences of posting it. You should only provide Content that you are comfortable sharing with others. By posting Your Posted Content, You represent, and warrant that:",
    "constent-10-1":
      "Your Posted Content does not and will not (i) infringe, violate, or misappropriate any third-party’s and/or intellectual property, privacy, or proprietary right, (ii) defame, harass, bully, or impersonate any other person, or (iii) violate any other law, rule, or regulation;",
    "constent-10-2":
      "You have, or have obtained, all rights, licenses, consents, permissions, power and/or authority necessary to grant the rights granted herein for any Posted Content that You submit, post, or display on or through the Services;",
    "constent-10-3":
      "Your Posted Content does not contain material subject to copyright or other proprietary rights, unless You have necessary permission or are otherwise legally entitled to post the material and to grant Memo’d the license described herein;",
    "constent-10-4":
      "Your Posted Content does not violate these Terms or Memo’d’s Terms of Service, including those additional terms and policies incorporated by reference; and",
    "constent-10-5":
      "Unless You have received prior written authorization, your Posted Content specifically does not contain any pre-release or non-public beta feature or content or any Confidential Information of Memo’d or confidential information of third parties.",
    "constent-11-1":
      "We may suspend or terminate Your account or cease providing You with all or part of the Services at any time for any or no reason, including, but not limited to, if we reasonably believe: (i) You have violated these Terms or the Terms of Service; (ii) You create risk or possible legal exposure for us; (iii) Your account should be removed due to unlawful conduct; (iv) Your account should be removed due to prolonged inactivity; or (v) our provision of the Services to You is no longer commercially viable. We will make reasonable efforts to notify You by the email address associated with Your account or the next time You attempt to access Your account, depending on the circumstances.",
    "constent-11-2":
      "Without limiting Our other rights or remedies, if We believe You have violated these Terms or the Terms of Service, We may determine that all or any portion of the earnings or winnings that You may otherwise have been entitled to receive will be void and forfeited.",
    "constent-11-3":
      "You may withdraw Your Entry in the Write to Win Contest by deleting Your Memo from the platform or untagging it at least 24 hours prior to Judging.",
    "constent-12":
      "Your access to and use of the Services or any Posted Content are at Your own risk. You understand and agree that the Services are provided to you on an “AS IS” and “AS AVAILABLE” basis. The “Memo’d Entities” refers to Memo’d, its related companies, affiliates, officers, directors, employees, agents, representatives, partners, and licensors. Without limiting the foregoing, to the maximum extent permitted under applicable law, THE MEMO’D ENTITIES DISCLAIM ALL WARRANTIES AND CONDITIONS, WHETHER EXPRESS OR IMPLIED, OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. The Memo’d Entities make no warranty or representation and disclaim all responsibility and liability for: (i) the completeness, accuracy, availability, timeliness, security or reliability of the Services or any Posted Content; (ii) any harm to Your computer system, loss of data, or other harm that results from access to or use of the Services or any Posted Content; (iii) the deletion of, or the failure to store or to transmit, any Posted Content and other communications maintained by the Services; and (iv) whether the Services will meet Your requirements or be available on an uninterrupted, secure, or error-free basis. No advice or information, whether oral or written, obtained from the Memo’d Entities or through the Services, will create any warranty or representation not expressly made herein.",
    "constent-13":
      "TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE MEMO’D ENTITIES SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES, WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM (i) YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE SERVICES; (ii) ANY CONDUCT OR CONTENT OF ANY THIRD PARTY ON THE SERVICES, INCLUDING WITHOUT LIMITATION, ANY DEFAMATORY, OFFENSIVE OR ILLEGAL CONDUCT OF OTHER USERS OR THIRD PARTIES; (iii) ANY CONTENT OBTAINED FROM THE SERVICES; OR (iv) UNAUTHORIZED ACCESS, USE OR ALTERATION OF YOUR TRANSMISSIONS OR CONTENT. IN NO EVENT SHALL THE AGGREGATE LIABILITY OF THE MEMO’D ENTITIES EXCEED THE GREATER OF ONE HUNDRED U.S. DOLLARS (U.S. $100.00) OR THE AMOUNT YOU PAID MEMO’D, IF ANY, IN THE PAST SIX MONTHS FOR THE SERVICES GIVING RISE TO THE CLAIM. THE LIMITATIONS OF THIS SECTION SHALL APPLY TO ANY THEORY OF LIABILITY, WHETHER BASED ON WARRANTY, CONTRACT, STATUTE, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE, AND WHETHER OR NOT THE MEMO’D ENTITIES HAVE BEEN INFORMED OF THE POSSIBILITY OF ANY SUCH DAMAGE, AND EVEN IF A REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.",
    "constent-14":
      "Creator will not assign, sell, transfer, delegate or otherwise dispose of, whether voluntarily or involuntarily, by operation of law or otherwise, this Agreement or any or its rights or obligations under this Agreement without Memo’d’s prior written consent. Memo’d may freely assign this Agreement or any of its rights or obligations under this Agreement without the consent of Creator.",
    "constent-15-1":
      "Any and all disputes and causes of action arising out of or connected with the Contest and the enforceability of these Terms, shall be governed by and construed in accordance with the laws of the state of California without regard to its conflict of law provisions. To the extent that any lawsuit is permitted under this Agreement, You and Memo’d hereby expressly consent to the personal and exclusive jurisdiction and venue of the state and federal courts located in the City and County of San Francisco, California, and waive any objection as to inconvenient forum.",
    "constent-15-2":
      "These, including the Terms of Service, Privacy Policy and any other terms and conditions which are incorporated herein or might accompany the Contest or applicable Service, constitute the entire agreement between You and Memo’d and shall govern the use of the Contest, superseding any prior agreement between You and Memo’d with respect to the Contest. You may also be subject to additional terms and conditions that may apply when You use or purchase certain other Memo’d Services, affiliate Services, third-party content, or third-party software.",
    "constent-15-3":
      "At any time, should Memo’d fail to exercise or enforce any right of provision of these Terms, such failure shall not constitute a waiver of such right or provision. If any provision of these Terms is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties’ intentions as reflected in the provision, and the other provisions of the Terms remain in full force and effect.",
    "constent-15-4":
      "You acknowledge and agree that Your account is non-transferable and any rights to Your account and/or contents within Your account shall terminate upon Your death.",
    "constent-15-5":
      "Memo’d may, from time to time, change these Terms. Such revisions shall be effective immediately. Your continued participation in the Contest(s) indicates Your acceptance of such changes, and as such, Your frequent review of this Agreement and policies currently in effect. Should You not agree to any updated, revised or modified terms, You must stop using the provided Services forthwith.",
    "constent-15-6":
      "We may assign our agreement with You to any affiliated company or to any entity that succeeds to all or substantially all of our business or assets related to the Contest. Neither these Terms nor any right, obligation, or remedy hereunder is assignable, transferable, delegatable, or sublicensable by You except with our prior written consent, and any attempted assignment, transfer, delegation, or sublicense shall be null and void.",
    "constent-15-7":
      "When You use the Services, or send e-mails, text messages, and other communications from Your computer or mobile device to us, You may be communicating with us electronically. You consent to receive communications from Memo’d electronically, such as e-mails, texts, mobile push notices, or notices, and messages through the Services, and you can retain copies of these communications for your records. You agree that all agreements, notices, disclosures, and other communications that we provide to You electronically satisfy any legal requirement that such communications be in writing.",
    "contest-footer": "Last updated: January 2024",
    "constest-2-bolds": [
      "Entry. ",
      "Memo’d Account. ",
      "Payment Setup. ",
      "Age Requirements. ",
      "Permission. ",
      "Businesses. ",
    ],
    "constest-3-bolds": [
      "(a) Writing. Memo’d will publicly post a topic or prompt which must be the subject of your Memo. ",
      "(b) Judging.",
      "Write to Win Contest Prizes and Benefits. ",
      "Availability. By Entering the Write to Win Contest You agree Your Memos and/or Memo’d profile will remain publicly available for a period of no less than six months from publication. ",
    ],
    "constest-4-bolds": [
      "Announcement. ",
      "Time. ",
      "Payment Processors. ",
      "Additional Fees. ",
      "Taxes on Platform Earnings. ",
    ],
    "constest-5-bolds": [
      "Memo’d Intellectual Property. ",
      "License Grant. ",
      "Release; Right of Publicity. ",
    ],
    "constest-11-bolds": [
      "Account Suspension or Termination. ",
      "Forfeiture. ",
      "Withdrawal. ",
    ],
    "constest-15-bolds": [
      "Applicable Law. ",
      "Entire Agreement. ",
      "Waiver; Severability. ",
      "No Right of Survivorship; Non-Transferability. ",
      "Changes to Write to Win Contest Terms. ",
      "Assignment. ",
      "Electronic Communications. ",
    ],
    price: "${price}",
    "constest-footer-Frequently": "Frequently Asked Questions",
    "constest-footer-Program": "Program Terms of Service",
    "constest-footer-Content": "Content Submission Guidelines",
    "constest-footer-Memo'd":
      "© 2023 Memo'd, Inc. Contact us at beta@memodapp.com",
  },
  help: {
    "more-questions": "Still have questions?",
    "contact-support": "Contact Support",
  },
  "download-app": {
    mobile: {
      header: "Memo’d: Quick reads to inspire",
      subheader: "Join the wisdom wave. Get the Memo'd app",
    },
    open: "Open",
  },
  "ab-test": {
    title: "Wisdom in 10 bullet points",
    "variant-a": {
      "bullet-1": "Get unlimited free access",
      "bullet-2": "Bookmark what inspires you",
      "bullet-3": "Create your own Memos",
      "bullet-4": "Grow smarter, faster",
    },
    "variant-b": {
      "bullet-1": "Beat procrastination",
      "bullet-2": "Remember complex ideas easily",
      "bullet-3": "Share knowledge with others",
      "bullet-4": "Grow smarter, faster",
    },
  },
  "ai-banner": {
    title: "Turn Ideas Into Insights",
    sub: "Write like a pro, even if you're not. AI magic at your fingertips.",
    button: "Create with AI",
  },
  "ai-popup": {
    subtitle: "Brainstorm and write with AI instantly",
    button: "Start Creating",
  },
};
