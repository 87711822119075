import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {
  size?: string | number;
}

export default function Wand(props: Props) {
  const { size = 48 } = props;

  return (
    <svg width={size} height={size} {...props} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path id="Vector" d="M15 2L11.3984 6.39844L5.80078 5.80078L6.39844 11.3984L2 15L6.39844 18.6016L5.80078 24.1992L11.3984 23.6016L15 28L16.7148 25.9414L33.7852 43.0156C35.328 44.5584 37.7077 44.3196 39.2148 42.8125L42.8125 39.2148C44.3196 37.7077 44.3196 35.093 42.8125 33.5859L25.9414 16.7148L28 15L23.6016 11.3984L24.1992 5.80078L18.6016 6.39844L15 2ZM38 6L36.8008 8.80078L34 10L36.8008 11.1992L38 14L39.1992 11.1992L42 10L39.1992 8.80078L38 6ZM15.6016 10C16.1016 10 16.6 10.2016 17 10.6016L22.9844 16.5859L16.5859 22.9844L10.6016 17C9.80156 16.2 9.80156 14.9992 10.6016 14.1992L14.1992 10.6016C14.5992 10.2016 15.1016 10 15.6016 10ZM24.3984 20.8281L39.9688 36.3984L36.3984 39.9727L20.8281 24.3984L24.3984 20.8281ZM12 30L10.2578 34.2578L6 36L10.2578 37.7422L12 42L13.7422 37.7422L18 36L13.7422 34.2578L12 30Z" fill="currentColor" />
    </svg>
  );
}