interface Props {
  size?: number;
  className?: string;
}

export default function Comments(props: Props) {
  const { size = 22 } = props;
  return (
    <svg width={size} height="20" className={props.className} viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M1.3999 9.224C1.3999 4.736 5.7679 1.2 10.9999 1.2C16.2319 1.2 20.5999 4.736 20.5999 9.224C20.5999 13.72 16.2319 17.248 10.9999 17.248C10.3679 17.248 9.7519 17.2 9.1519 17.096C8.7039 17.056 8.4479 17.088 7.9599 17.24C7.8079 17.288 7.6159 17.392 7.3439 17.536L7.3279 17.544C6.7519 17.856 5.9359 18.288 4.9199 18.288C4.8799 18.288 4.7679 18.28 4.6719 18.192C4.5599 18.088 4.5439 17.96 4.5519 17.888C4.5599 17.824 4.5759 17.776 4.5839 17.752C4.5919 17.728 4.6079 17.712 4.6159 17.704C4.6399 17.672 4.6639 17.64 4.6799 17.624C4.7119 17.592 4.7519 17.544 4.7999 17.504L4.8079 17.496C4.9759 17.328 5.2399 17.072 5.4559 16.784C5.6879 16.48 5.8159 16.208 5.8159 16.032V15.968C3.1759 14.56 1.3999 12.088 1.3999 9.224Z" stroke="currentColor" strokeOpacity="0.6" strokeWidth="1.48" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}