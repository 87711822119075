interface Props {
  size?: number;
  className?: string;
}

export default function MenuArrow(props: Props) {
  const { size, className } = props;
  return (
    <svg width={size} className={className} height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 0L13.9282 9H0.0717969L7 0Z" fill="currentColor"/>
    </svg>
  );
}