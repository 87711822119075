type Props = {
  size?: number;
  className?: string;
}

export default function Instagram(props: Props) {
  const { size = 20, className } = props;
  const height = (size / 20) * 21;

  return(
    <svg width={size} height={height} className={className} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M10 0.308838C7.28417 0.308838 6.94417 0.320505 5.8775 0.368838C2.24583 0.535505 0.2275 2.5505 0.0608333 6.1855C0.0116667 7.253 0 7.593 0 10.3088C0 13.0247 0.0116667 13.3655 0.06 14.4322C0.226667 18.0638 2.24167 20.0822 5.87667 20.2488C6.94417 20.2972 7.28417 20.3088 10 20.3088C12.7158 20.3088 13.0567 20.2972 14.1233 20.2488C17.7517 20.0822 19.775 18.0672 19.9392 14.4322C19.9883 13.3655 20 13.0247 20 10.3088C20 7.593 19.9883 7.253 19.94 6.18634C19.7767 2.558 17.7592 0.536338 14.1242 0.369671C13.0567 0.320505 12.7158 0.308838 10 0.308838ZM10 5.17384C7.16417 5.17384 4.865 7.473 4.865 10.3088C4.865 13.1447 7.16417 15.4447 10 15.4447C12.8358 15.4447 15.135 13.1455 15.135 10.3088C15.135 7.473 12.8358 5.17384 10 5.17384ZM10 13.6422C8.15917 13.6422 6.66667 12.1505 6.66667 10.3088C6.66667 8.468 8.15917 6.9755 10 6.9755C11.8408 6.9755 13.3333 8.468 13.3333 10.3088C13.3333 12.1505 11.8408 13.6422 10 13.6422ZM15.3383 3.77134C14.675 3.77134 14.1375 4.30884 14.1375 4.97134C14.1375 5.63384 14.675 6.17134 15.3383 6.17134C16.0008 6.17134 16.5375 5.63384 16.5375 4.97134C16.5375 4.30884 16.0008 3.77134 15.3383 3.77134Z" fill="currentColor"/>
    </svg>
  );
}