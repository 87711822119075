interface Props {
  size?: number;
  height?: number;
  className?: string;
  fill?: string;
}

export default function ForwardArrow(props: Props) {
  const { size = 12, fill = "white" } = props;
  const height = (size / 12) * 6;

  return(
    <svg width={size} height={height} viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M8.78792 0.0878679C8.90507 -0.0292894 9.09502 -0.0292894 9.21218 0.0878679L11.612 2.48764L11.6122 2.48787C11.7293 2.60503 11.7293 2.79498 11.6122 2.91213L9.21218 5.31213C9.09502 5.42929 8.90507 5.42929 8.78792 5.31213C8.67076 5.19497 8.67076 5.00503 8.78792 4.88787L10.6758 3H0.600049C0.434363 3 0.300049 2.86569 0.300049 2.7C0.300049 2.53431 0.434363 2.4 0.600049 2.4H10.6758L8.78792 0.512132C8.67076 0.394975 8.67076 0.205025 8.78792 0.0878679Z" fill={fill} />
    </svg>
  );
}