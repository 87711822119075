interface Props {
  className?: string;
  size?: number;
  height?: number;
}

export default function Drag(props: Props) {
  const { className, size = 32, height = 48 } = props;
  return (
    <svg width={size} height={height} className={className} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.6709 9.33398H29.3379" stroke="white" strokeWidth="1.66669" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M2.6665 16H29.3269" stroke="white" strokeWidth="1.66669" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M2.6709 22.668H29.3268" stroke="white" strokeWidth="1.66669" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}