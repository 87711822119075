interface Props {
  size?: number;
  className?: string;
  active?: boolean;
  height?: number;
}


export default function Like(props: Props) {
  const { size = 21, className } = props;
  const height = size / 21 * 18;

  if (props.active) {
    return (
      <svg width={size} className={className} height={height} viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.5 16.6002C27.4709 5.75532 16.2877 -3.02503 10.5 2.92218C4.71227 -3.02503 -6.47086 5.75532 10.5 16.6002Z" fill="#05A88F" stroke="#05A88F" strokeWidth="1.4821" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    );
  }


  return (
    <svg width={size} className={className} height={height} viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.5 16.6002C27.4709 5.75532 16.2877 -3.02503 10.5 2.92218C4.71227 -3.02503 -6.47086 5.75532 10.5 16.6002Z" stroke="currentColor" strokeWidth="1.4821" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
    // <svg width={size} className={className} height={height} viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <path d="M9 14.3347C23.1424 5.29729 13.8231 -2.01966 9 2.93634C4.17689 -2.01966 -5.14238 5.29729 9 14.3347Z" stroke="currentColor" strokeWidth="1.4821" strokeLinecap="round" strokeLinejoin="round"/>
    // </svg>
  );
}