interface Props {
  size?: number;
  className?: string;
  active?: boolean;
  height?: number;
  onClick?: VoidFunction;
  fill?: string;
  stroke?: string
}


export default function ThreeDots(props: Props) {
  const { size = 21, className, fill = "white", stroke } = props;
  const height = (size / 21) * 4;

  if (props.active) {
    return (
      <svg width={size} className={className} height={height} viewBox="0 0 21 4" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M4.41553 2C4.41553 3.10457 3.5201 4 2.41553 4C1.31096 4 0.415527 3.10457 0.415527 2C0.415527 0.89543 1.31096 0 2.41553 0C3.5201 0 4.41553 0.89543 4.41553 2ZM12.4155 2C12.4155 3.10457 11.5201 4 10.4155 4C9.31096 4 8.41553 3.10457 8.41553 2C8.41553 0.89543 9.31096 0 10.4155 0C11.5201 0 12.4155 0.89543 12.4155 2ZM18.4155 4C19.5201 4 20.4155 3.10457 20.4155 2C20.4155 0.89543 19.5201 0 18.4155 0C17.311 0 16.4155 0.89543 16.4155 2C16.4155 3.10457 17.311 4 18.4155 4Z" fill={fill} />
      </svg>
    );
  }

  return (
    <svg width={size} height={height} className={className} viewBox="0 0 21 4" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M4.41553 2C4.41553 3.10457 3.5201 4 2.41553 4C1.31096 4 0.415527 3.10457 0.415527 2C0.415527 0.89543 1.31096 0 2.41553 0C3.5201 0 4.41553 0.89543 4.41553 2ZM12.4155 2C12.4155 3.10457 11.5201 4 10.4155 4C9.31096 4 8.41553 3.10457 8.41553 2C8.41553 0.89543 9.31096 0 10.4155 0C11.5201 0 12.4155 0.89543 12.4155 2ZM18.4155 4C19.5201 4 20.4155 3.10457 20.4155 2C20.4155 0.89543 19.5201 0 18.4155 0C17.311 0 16.4155 0.89543 16.4155 2C16.4155 3.10457 17.311 4 18.4155 4Z" stroke={stroke} fill={fill} />
    </svg>
  );
}