interface Props {
  size?: number;
  className?: string;
  style?: "solid" | "outline";
}

export default function Email(props: Props) {
  const { size = 17, className, style = "solid" } = props;

  if (style === "outline") {
    const height = 36 * (size / 48);
    return (
      <svg width={size} height={height} viewBox="0 0 48 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.22654 0.16626C2.63448 0.16626 0.533203 2.26754
          0.533203 4.85959V31.1423C0.533203 33.7343 2.63448 35.8356 5.22654 35.8356H42.7732C45.3653 35.8356 47.4665 33.7343 47.4665
          31.1423V4.85959C47.4665 2.26754 45.3653 0.16626 42.7732 0.16626H5.22654ZM2.74888 3.52015C3.22528 2.64078 4.15617 2.04359 5.22654
          2.04359H42.7732C43.8439 2.04359 44.775 2.64111 45.2513 3.52088L25.9916 22.7805C24.8919 23.8802 23.1089 23.8802 22.0092 22.7805L2.74888
          3.52015ZM2.41054 5.83675V30.2027L14.5936 18.0198L2.41054 5.83675ZM5.22654 33.9583C4.16604 33.9583 3.24247 33.372 2.76218 32.506L15.9211
          19.3473L20.6817 24.108C22.5146 25.9408 25.4863 25.9408 27.3191 24.108L31.8042 19.6229L45.0207 32.8391C44.5066 33.519 43.6912 33.9583 42.7732
          33.9583H23.9999H5.22654ZM45.5892 30.7527V5.83788L33.1317 18.2954L45.5892 30.7527Z"
          fill="currentColor"
        />
      </svg>
    );
  }

  return (
    <svg width={size} height="13" fill="none" className={className} xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M16.518 4.128c-.73.566-1.693 1.289-5.013 3.7-.66.483-1.843 1.56-3.005 1.55-1.155.007-2.314-1.048-3.001-1.55-3.32-2.411-4.287-3.13-5.016-3.697a.195.195 0 00-.316.153v6.654c0 .862.7 1.562 1.562 1.562h13.542c.863 0 1.563-.7 1.563-1.563V4.282a.196.196 0 00-.316-.153zM8.5 8.333c.756.013 1.843-.95 2.39-1.347 4.32-3.135 4.648-3.409 5.644-4.19a.779.779 0 00.3-.615v-.619C16.834.7 16.134 0 15.27 0H1.73C.867 0 .167.7.167 1.563v.618c0 .24.11.465.3.615.996.778 1.324 1.055 5.644 4.19.547.397 1.634 1.36 2.39 1.347z" fill="#fff"></path></svg>
  );
}