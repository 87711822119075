type Props = {
  size?: number;
}

export default function TipBox(props: Props) {
  const { size = 56 } = props;

  return (
    <svg width={size} height={size} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Tip shoebox">
        <path id="Box" fillRule="evenodd" clipRule="evenodd" d="M11.2486 5.22223C10.8409 5.22223 10.474 5.46973 10.3213 5.84777L5.30415 18.2712C5.27616 18.3362 5.25485 18.4047 5.2411 18.4759C5.22719 18.5471 5.22129 18.6186 5.22292 18.6893V45.1111C5.22292 47.3816 7.06353 49.2222 9.33403 49.2222H46.6674C48.9379 49.2222 50.7785 47.3816 50.7785 45.1111V18.6893C50.7801 18.6186 50.7742 18.5471 50.7603 18.4759C50.7466 18.4047 50.7252 18.3362 50.6973 18.2713L45.6801 5.84777C45.5274 5.46973 45.1605 5.22223 44.7528 5.22223H11.2486ZM48.2962 17.6667L44.0782 7.22223H11.9232L7.70523 17.6667H48.2962ZM7.22292 19.6667H48.7785V45.1111C48.7785 46.2771 47.8333 47.2222 46.6674 47.2222H9.33403C8.1681 47.2222 7.22292 46.2771 7.22292 45.1111V19.6667Z" fill="currentColor" />
        <path id="Coin" fillRule="evenodd" clipRule="evenodd" d="M36.8023 12.4444C37.1463 11.4714 37.3334 10.4242 37.3334 9.33333C37.3334 4.17868 33.1547 0 28.0001 0C22.8454 0 18.6667 4.17868 18.6667 9.33333C18.6667 10.4242 18.8539 11.4714 19.1978 12.4444H36.8023Z" fill="currentColor" />
      </g>
    </svg>
  );
}