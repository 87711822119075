import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {
  size?: string | number;
}

export default function Confused(props: Props) {
  const { size = 40 } = props;

  return (
    <svg width={size} height={size} {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20 0C8.97799 0 0 8.97799 0 20C0 31.022 8.97799 40 20 40C31.022 40 40 31.022 40 20C40 8.97799 31.022 0 20 0ZM20 4C28.8602 4 36 11.1398 36 20C36 28.8602 28.8602 36 20 36C11.1398 36 4 28.8602 4 20C4 11.1398 11.1398 4 20 4ZM13 14C12.2044 14 11.4413 14.3161 10.8787 14.8787C10.3161 15.4413 10 16.2044 10 17C10 17.7956 10.3161 18.5587 10.8787 19.1213C11.4413 19.6839 12.2044 20 13 20C13.7956 20 14.5587 19.6839 15.1213 19.1213C15.6839 18.5587 16 17.7956 16 17C16 16.2044 15.6839 15.4413 15.1213 14.8787C14.5587 14.3161 13.7956 14 13 14ZM27 14C26.2044 14 25.4413 14.3161 24.8787 14.8787C24.3161 15.4413 24 16.2044 24 17C24 17.7956 24.3161 18.5587 24.8787 19.1213C25.4413 19.6839 26.2044 20 27 20C27.7956 20 28.5587 19.6839 29.1213 19.1213C29.6839 18.5587 30 17.7956 30 17C30 16.2044 29.6839 15.4413 29.1213 14.8787C28.5587 14.3161 27.7956 14 27 14ZM23.0039 24.0273C21.5084 24.1722 20.0859 24.7555 19.1484 25.1875C17.8784 25.8255 15.2 27.25 12 26C12.924 27.606 14.056 29.3405 15.75 29.8125C17.448 30.2805 18.7034 29.7984 20.1094 29.0664C21.5414 28.3364 23.4839 27.4888 24.4219 27.2188C25.3379 26.8528 26.578 27.044 28 28C27.4 24.688 24.9798 24.1568 24.5078 24.0508C24.0073 23.9803 23.5024 23.9791 23.0039 24.0273Z" fill="currentColor"/>
    </svg>
  );
}