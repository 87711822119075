interface Props {
  size?: number;
  className?: string;
}


export default function Menu(props: Props) {
  const { size = 16, className } = props;
  return (
    <svg width={size} className={className} height="4" viewBox="0 0 16 4" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M2.16667 3.6661C3.08714 3.6661 3.83333 2.9199 3.83333 1.99943C3.83333 1.07896 3.08714 0.332764 2.16667 0.332764C1.24619 0.332764 0.5 1.07896 0.5 1.99943C0.5 2.9199 1.24619 3.6661 2.16667 3.6661ZM7.99991 3.6661C8.92038 3.6661 9.66658 2.9199 9.66658 1.99943C9.66658 1.07896 8.92038 0.332764 7.99991 0.332764C7.07943 0.332764 6.33324 1.07896 6.33324 1.99943C6.33324 2.9199 7.07943 3.6661 7.99991 3.6661ZM13.8332 3.6661C14.7536 3.6661 15.4998 2.9199 15.4998 1.99943C15.4998 1.07896 14.7536 0.332764 13.8332 0.332764C12.9127 0.332764 12.1665 1.07896 12.1665 1.99943C12.1665 2.9199 12.9127 3.6661 13.8332 3.6661Z" fill="currentColor"/>
    </svg>
  );
}