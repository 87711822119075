import { CSSProperties, useMemo } from "react";

const defaultStyle: CSSProperties = {
  stroke: "#626262",
  fill: "none",
  strokeWidth: "3px",
  strokeLinecap: "round",
};

interface Props {
  size?: number;
  cssStyles?: CSSProperties;
}

export default function Loading(props: Props) {
  const { size = 50 } = props;
  const style = useMemo(() => {
    return {
      ...defaultStyle,
      ...props.cssStyles,
    };
  }, [])

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 50 50"
    >
      <circle
        fill="none"
        opacity="0.10"
        stroke="#000000"
        strokeWidth="3"
        cx="25"
        cy="25"
        r="20"
      />
      <g transform="translate(25,25) rotate(-90)">
        <circle
          style={style}
          strokeDasharray="110"
          strokeDashoffset="0"
          cx="0"
          cy="0"
          r="20"
        >
          <animate
            attributeName="stroke-dashoffset"
            values="360;140"
            dur="2.2s"
            keyTimes="0;1"
            calcMode="spline"
            fill="freeze"
            keySplines="0.41,0.314,0.8,0.54"
            repeatCount="indefinite"
            begin="0"
          />
          <animateTransform
            attributeName="transform"
            type="rotate"
            values="0;274;360"
            keyTimes="0;0.74;1"
            calcMode="linear"
            dur="2.2s"
            repeatCount="indefinite"
            begin="0"
          />
        </circle>
      </g>
    </svg>
  );
}
