import Disappointed from "./disappointed";
import Confused from "./confused";
import Content from "./content";
import VeryHappy from "./very-happy";
import SmileyHeart from "./smiley-heart";

export default {
  Disappointed,
  Confused,
  Content,
  VeryHappy,
  SmileyHeart,
}
