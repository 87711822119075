import { useEffect, useRef } from "react";
import App, { AppContext, AppInitialProps, AppProps } from "next/app";
import Head from "next/head";
import Script from "next/script";
import { useRouter } from "next/router";
import Modal from "react-modal";
import { ToastContainer } from "react-toastify";
import { WriterInterface } from "@carglassgithub/memod-sdk";
import { sdkv1 } from "@models/api";
import { ApplicationProvider } from "@models/state";
import useAmplitude from "@models/interactions/use-amplitude";
import { EditorLinkCard } from "@components/molecules/web-component/editor-link-card";
import { MemoLinkCard } from "@components/molecules/web-component/memo-link-card";
import { AnimatePresence } from "framer-motion";
import cache from "memory-cache";
import "@theme/style.css";

Modal.setAppElement("#__next");

type Props = { user: WriterInterface };

const isClient = globalThis.window !== undefined;

const usePreviousRoute = () => {
  const router = useRouter();

  const ref = useRef<string>("");

  router.events?.on("routeChangeStart", () => {
    ref.current = router.asPath || "";
  });

  return ref.current;
};

export default function MyApp({
  Component,
  pageProps,
  user,
}: AppProps & Props) {
  const { operations } = useAmplitude();
  const previousRoute = usePreviousRoute();

  useEffect(() => {
    if (isClient) {
      operations.initialize();

      customElements.define("editor-link-card", EditorLinkCard());
      customElements.define("memo-link-card", MemoLinkCard());
    }
  }, []);

  return (
    <ApplicationProvider user={user} previousRoute={previousRoute}>
      <Head>
        <title>Memo'd - Discover and share life-changing ideas</title>
        <link
          rel="shortcut icon"
          href="https://memod.com/favicon.ico"
          type="image/x-icon"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="language" content="EN" />
        <meta
          name="description"
          property="description"
          content="Meet Memo'd - The knowledge-sharing app where you can discover and share life-changing ideas that can be read in less than 2 minutes"
        />
        <meta name="keywords" content="" />
        <meta
          name="og:title"
          property="og:title"
          content="Memo'd - Discover and share life-changing ideas"
        />
        <meta
          name="og:description"
          property="og:description"
          content="Meet Memo'd - The knowledge-sharing app where you can discover and share life-changing ideas that can be read in less than 2 minutes"
        />
        <meta name="og:url" property="og:url" content="https://memod.com" />
        <meta
          name="og:image"
          property="og:image"
          content="https://memod.com/home-art.png"
        />
        <meta name="og:image:width" property="og:image:width" content="1200" />
        <meta name="og:image:height" property="og:image:height" content="600" />
        <meta
          name="twitter:title"
          property="twitter:title"
          content="Memo'd - Discover and share life-changing ideas"
        />
        <meta
          name="twitter:description"
          property="twitter:description"
          content="Meet Memo'd - The knowledge-sharing app where you can discover and share life-changing ideas that can be read in less than 2 minutes"
        />
        <meta
          name="twitter:card"
          property="twitter:card"
          content="summary_large_image"
        />
        <meta name="twitter:site" property="twitter:site" content="@memodapp" />
        <meta
          name="twitter:image"
          property="twitter:image"
          content="https://memod.com/home-art.png"
        />
      </Head>

      <AnimatePresence mode="wait" initial={false}>
        <Component {...pageProps} />
      </AnimatePresence>
      <Script
        src="https://connect.facebook.net/en_US/sdk.js"
        strategy="afterInteractive"
        id="facebook-jssdk"
      />
      <Script
        id={process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}`}
        defer
      />
      <Script
        id={process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />
      <ToastContainer
        toastClassName="!bg-memod-grey-based rounded-xl border border-notification-dark/[.65] px-5"
        bodyClassName="text-white text-lg"
        autoClose={5000}
        closeButton={false}
        hideProgressBar={true}
        limit={5}
      />
    </ApplicationProvider>
  );
}

MyApp.getInitialProps = async (context: AppContext): Promise<Props> => {
  console.time("load time");

  const _token = context.ctx.req?.headers?.cookie?.match(
    /\b(?:token|KANVAS_SDK_TOKEN)=([^;]+)(?:;|$)/
  )?.[1]!;
  const headers = _token
    ? { headers: { Authorization: `Bearer ${_token}` } }
    : {};

  let user = null;

  try {
    if (_token) {
      if (cache.get(_token)) {
        user = cache.get(_token);
      } else {
        const { data } = await sdkv1.http.http.get(`/users/0`, headers);
        user = data;
        // only keep for 1 hour
        cache.put(_token, user, 1 * 60 * 60 * 1000);
      }
    }
  } catch (e) {
    console.log({ e });
  }

  console.timeEnd("load time");

  return { user };
};
