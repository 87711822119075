interface Props {
  direction?: 'left' | 'right';
  className?: string;
  size?: number;
  opacity?: number;
}

export default function Chevron(props: Props) {
  const { className, direction, size = 9, opacity = 1 } = props;

  if (direction === 'right') {
    return (
      <svg width={size} height="15" className={className} viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.65993 0.799806L0.799803 7.05801L7.65992 13.5998" stroke="currentColor" strokeOpacity={opacity} strokeWidth="1.4821" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    )
  }

  return (
    <svg width={size} height="15" className={className} viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.34007 0.799806L8.2002 7.05801L1.34008 13.5998" stroke="currentColor" strokeOpacity={opacity} strokeWidth="1.4821" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}