interface Props {
  className?: string;
  size?: number;
}
export default function Verify(props: Props) {
  const { size = 64 } = props;

  return(
    <svg width={size} height={size} className={props.className} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24.7178 32.3832L30.2367 37.9021L41.2745 26.8643" stroke="#D7D9DB" strokeOpacity="0.4" strokeWidth="2.16938" strokeLinecap="round"/>
      <rect x="14.5564" y="13.7859" width="36.8795" height="36.8795" rx="18.4398" stroke="#D7D9DB" strokeOpacity="0.4" strokeWidth="2.16938"/>
    </svg>
  );
}