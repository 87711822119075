type Props = {
  size?: number;
}

export default function Send({ size = 23 }: Props) {
  return(
    <svg width={size} height={size} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M3.65964 19.3346L19.3646 12.6026C20.0936 12.2876 20.0936 11.2616 19.3646 10.9466L3.65964 4.21458C3.06564 3.95358 2.40864 4.39458 2.40864 5.03358L2.39964 9.18258C2.39964 9.63258 2.73264 10.0196 3.18264 10.0736L15.8996 11.7746L3.18264 13.4666C2.73264 13.5296 2.39964 13.9166 2.39964 14.3666L2.40864 18.5156C2.40864 19.1546 3.06564 19.5956 3.65964 19.3346V19.3346Z" fill="#F7F7F7"/>
    </svg>
  );
}
