export default function Added() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15" cy="15" r="15" fill="white" />
      <path
        d="M10.458 15.0862L13.4866 18.1148L19.5437 12.0576"
        stroke="#3A3A3C"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
