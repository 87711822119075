interface Props {
  size?: number;
  className?: string;
  height?: number;
}

export default function Trophy(props: Props) {
  const { size = 64, className, height = 64 } = props;

  return(
    <svg width={size} height={height} className={className} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M40.7832 47.5327H23.1259C22.4321 47.5327 21.8701 48.0847 21.8701 48.7663C21.8701 49.4479 22.4321 50 23.1259 50H40.7832C41.477 50 42.039 49.4479 42.039 48.7663C42.039 48.0847 41.4776 47.5327 40.7832 47.5327ZM51.8304 16.4506C51.7117 15.8769 51.1962 15.4649 50.6004 15.4649H44.4539V14.2337C44.4539 13.5521 43.8919 13 43.198 13H20.6117C19.9179 13 19.3559 13.5521 19.3559 14.2337V15.4649H13.3996C12.8038 15.4649 12.2882 15.8769 12.1696 16.4506C12.1325 16.6288 11.2905 20.9516 13.7356 23.8969C15.1421 25.5901 17.2913 26.4771 20.1125 26.5561C21.7212 31.2267 25.7661 34.6853 30.6374 35.201V43.7947H26.8787C26.1849 43.7947 25.6229 44.3467 25.6229 45.0283C25.6229 45.7099 26.1849 46.262 26.8787 46.262H37.0307C37.7246 46.262 38.2865 45.7099 38.2865 45.0283C38.2865 44.3467 37.7246 43.7947 37.0307 43.7947H33.149V35.2022C38.0285 34.6964 42.0822 31.2391 43.6953 26.5635C46.6125 26.5216 48.8259 25.629 50.2645 23.8969C52.7095 20.951 51.8675 16.6288 51.8304 16.4506ZM15.6903 22.3462C14.5783 21.0163 14.4665 19.0535 14.523 17.9321H19.356V22.097C19.356 22.7638 19.4188 23.4139 19.5105 24.0542C17.7963 23.8969 16.5104 23.3282 15.6903 22.3462ZM41.9423 22.097C41.9423 27.9988 37.4402 32.8014 31.9071 32.8014C26.3715 32.8014 21.8682 27.9994 21.8682 22.097V15.4673H41.9429L41.9423 22.097ZM48.3098 22.3461C47.459 23.3652 46.1109 23.9456 44.2963 24.0757C44.3898 23.4287 44.4539 22.7711 44.4539 22.0969V17.9321H49.4771C49.5336 19.0547 49.4219 21.0163 48.3098 22.3461Z" fill="#67686A" />
    </svg>
  );
}