interface Props {
  className?: string;
  size?: number;
}

export default function Pencil(props: Props) {
  const { className, size = 48 } = props;

  return(
    <svg width={size} height={size} className={className} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" fill="white" fillOpacity="0.01" />
      <path d="M27.9811 16.6143L32.5091 21.1423L13.8051 39.8303L9.00509 41.0783C8.73037 41.1499 8.43826 41.0706 8.23751 40.8698C8.03675 40.6691 7.95744 40.377 8.02909 40.1023L9.27709 35.3023L27.9811 16.6143Z" stroke="white" />
      <path d="M31.8118 12.7598L34.3121 10.2594C35.5618 9.00976 37.5879 9.00976 38.8376 10.2594V10.2594C40.0873 11.5091 40.0873 13.5352 38.8376 14.7849L36.3373 17.2852L31.8118 12.7598Z" stroke="white" />
    </svg>

  );
}