import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {
  size?: string | number;
}

export default function Content(props: Props) {
  const { size = 40 } = props;

  return (
    <svg width={size} height={size} {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.98 0C8.94 0 0 8.96 0 20C0 31.04 8.94 40 19.98 40C31.04 40 40 31.04 40 20C40 8.96 31.04 0 19.98 0ZM20 36C11.16 36 4 28.84 4 20C4 11.16 11.16 4 20 4C28.84 4 36 11.16 36 20C36 28.84 28.84 36 20 36ZM27 18C28.66 18 30 16.66 30 15C30 13.34 28.66 12 27 12C25.34 12 24 13.34 24 15C24 16.66 25.34 18 27 18ZM13 18C14.66 18 16 16.66 16 15C16 13.34 14.66 12 13 12C11.34 12 10 13.34 10 15C10 16.66 11.34 18 13 18ZM20 31C24.66 31 28.62 28.08 30.22 24H9.78C11.38 28.08 15.34 31 20 31Z" fill="currentColor"/>
    </svg>
  );
}