export default function EmailUnsubscribe() {
  return(
    <svg width="121" height="123" viewBox="0 0 121 123" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M57.9031 2.21674C59.3989 0.939878 61.6011 0.939875 63.0969 2.21674L119.595 50.4434C120.487 51.2043 120.999 52.3177 120.998 53.4897L120.935 119.004C120.933 121.211 119.143 123 116.935 123H4.06512C1.85749 123 0.0672604 121.211 0.065126 119.004L0.00178576 53.4897C0.000652732 52.3177 0.513501 51.2043 1.40484 50.4434L57.9031 2.21674Z"
        fill="#057D6B" />
      <rect x="16.0002" y="17" width="89" height="73" rx="4" fill="white" />
      <g clip-path="url(#clip0_20066_418155)">
        <path
          d="M47.0793 62.1952C55.5855 59.2492 65.461 59.4566 73.9672 62.3612C77.0378 63.44 79.9838 64.9338 82.5564 66.9255C83.3448 67.5479 84.8386 68.8342 85.793 67.6309C86.3324 66.9255 85.876 65.7637 85.5025 65.0998C84.5896 63.4815 83.0129 62.3612 81.5606 61.2824C79.6519 59.8716 77.5357 58.7512 75.3365 57.8384C71.6021 56.3031 67.5772 55.5147 63.5523 55.2243C62.017 55.1413 60.5233 55.0998 58.988 55.1413C58.9465 55.1413 58.905 55.1413 58.8635 55.1413C56.7058 55.2243 54.5482 55.4317 52.432 55.8052C48.3656 56.5106 44.4237 57.9214 40.8137 59.9131C40.4403 60.1205 40.0253 60.3695 39.6519 60.577C38.3656 61.3653 37.1208 62.1537 36.3739 63.523C36.1249 64.0209 35.9589 64.6019 36.1249 65.1413C36.4154 66.0541 37.3697 66.3861 38.2411 66.2201C39.6104 65.9297 40.7307 65.1413 41.8926 64.4774C43.1374 63.772 44.4652 63.1496 45.793 62.6102C46.2079 62.4857 46.6228 62.3612 47.0793 62.1952Z"
          fill="#333333" />
        <path
          d="M47.411 43.8545C48.1994 43.7301 48.9048 43.3151 49.5687 42.9417C50.4401 42.4022 51.3944 41.8628 51.9339 40.9915C52.1828 40.5765 52.3073 40.0371 52.0168 39.6637C51.8094 39.4147 51.4774 39.3317 51.1455 39.2902C48.9878 39.0827 46.8301 40.6595 44.7139 40.1616C43.8011 39.9541 39.0293 37.5475 40.4401 40.3276C41.3944 42.2778 45.3778 44.145 47.411 43.8545Z"
          fill="#333333" />
        <path
          d="M71.1042 43.8545C70.3158 43.7301 69.6104 43.3151 68.9465 42.9417C68.0751 42.4022 67.1208 41.8628 66.5813 40.9915C66.3324 40.5765 66.2079 40.0371 66.4984 39.6637C66.7058 39.4147 67.0378 39.3317 67.3282 39.2902C69.5274 39.0827 71.6436 40.6595 73.7598 40.1616C74.6726 39.9541 79.4444 37.5475 78.0751 40.3276C77.0793 42.2778 73.1374 44.145 71.1042 43.8545Z"
          fill="#333333" />
      </g>
      <path d="M1.08691 121.034V52.6429L49.3977 86.8171L1.08691 121.034Z" fill="#00CBAC" stroke="#53E6E6" />
      <path d="M119.912 121.034L71.6013 86.8171L119.912 52.6429V121.034Z" fill="#00CBAC" stroke="#48E5E5" />
      <path d="M70.7797 86.8164H50.2632L0.586914 122H120.412L70.7797 86.8164Z" fill="#05A88F" />
      <defs>
        <clipPath id="clip0_20066_418155">
          <rect width="50" height="29.1286" fill="white" transform="translate(36.0002 39)" />
        </clipPath>
      </defs>
    </svg>

  );
}