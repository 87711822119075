export default function BulletsIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.17676 8.92212C7.90062 8.92212 7.67676 9.14598 7.67676 9.42212C7.67676 9.69826 7.90062 9.92212 8.17676 9.92212H8.3012C8.57734 9.92212 8.8012 9.69826 8.8012 9.42212C8.8012 9.14598 8.57734 8.92212 8.3012 8.92212H8.17676ZM8.17676 15.1443C7.90062 15.1443 7.67676 15.3682 7.67676 15.6443C7.67676 15.9205 7.90062 16.1443 8.17676 16.1443H8.3012C8.57734 16.1443 8.8012 15.9205 8.8012 15.6443C8.8012 15.3682 8.57734 15.1443 8.3012 15.1443H8.17676ZM7.67676 21.8666C7.67676 21.5904 7.90062 21.3666 8.17676 21.3666H8.3012C8.57734 21.3666 8.8012 21.5904 8.8012 21.8666C8.8012 22.1427 8.57734 22.3666 8.3012 22.3666H8.17676C7.90062 22.3666 7.67676 22.1427 7.67676 21.8666ZM11.9129 21.3666C11.6367 21.3666 11.4129 21.5904 11.4129 21.8666C11.4129 22.1427 11.6367 22.3666 11.9129 22.3666H24.3573C24.6335 22.3666 24.8573 22.1427 24.8573 21.8666C24.8573 21.5904 24.6335 21.3666 24.3573 21.3666H11.9129ZM11.4129 15.6443C11.4129 15.3682 11.6367 15.1443 11.9129 15.1443H24.3573C24.6335 15.1443 24.8573 15.3682 24.8573 15.6443C24.8573 15.9205 24.6335 16.1443 24.3573 16.1443H11.9129C11.6367 16.1443 11.4129 15.9205 11.4129 15.6443ZM11.9129 8.92212C11.6367 8.92212 11.4129 9.14598 11.4129 9.42212C11.4129 9.69826 11.6367 9.92212 11.9129 9.92212H24.3573C24.6335 9.92212 24.8573 9.69826 24.8573 9.42212C24.8573 9.14598 24.6335 8.92212 24.3573 8.92212H11.9129Z"
        fill="white"
      />
      <rect
        x="4.44434"
        y="4.44434"
        width="23.6444"
        height="23.6444"
        rx="3.33333"
        stroke="white"
        strokeLinecap="round"
      />
    </svg>
  );
}
