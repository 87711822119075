import Add from "./add";
import Apple from "./apple";
import ArrowBack from "./arrow-back";
import Bullet from "./bullet";
import Chevron from "./chevron";
import Close from "./close";
import Comments from "./comments";
import Eye from "./eye";
import { Facebook, FacebookSolid } from "./facebook";
import Flag from "./flag";
import Like from "./like";
import { Memod, MiniMemod } from "./memod";
import Menu from "./menu";
import MenuArrow from "./menu-arrow";
import Save from "./save";
import Saved from "./saved";
import Share from "./share";
import Twitter from "./twitter";
import UserVerified from "./user-verified";
import Email from "./email";
import Loading from "./loading";
import Papers from "./papers";
import Girl from "./girl";
import Book from "./book";
import UserAvatar from "./user-avatar";
import List from "./list";
import Tag from "./tag";
import Verify from "./verify";
import ArrowNext from "./arrow-next";
import Done from "./done";
import Submitted from "./submitted";
import Send from "./send";
import Youtube from "./social/youtube";
import Link from "./social/link";
import Instagram from "./social/instagram";
import Linkedin from "./social/linkedin";
import Whatsapp from "./social/whatsapp";
import Reddit from "./social/reddit";
import Edit from "./edit";
import Trash from "./trash";
import ForwardArrow from "./forward-arrow";
import Warning from "./warning";
import QuestionMark from "./question-mark";
import Lock from "./lock";
import HashTag from "./hashtag";
import ThreeDots from "./three-dots";
import {
  ImageUpload,
  Search,
  Spin,
  Unsplash,
  Upload,
  UpploadBackArrow,
  Zoom,
} from "./upload";
import Trophy from "./trophy";
import ErrorIcon from "./error";
import Remove from "./remove";
import Drag from "./drag";
import Down from "./down";
import Plus from "./plus";
import BugerMenu from "./burger-menu";
import Bell from "./bell";
import SearchIcon from "./search";
import User from "./user";
import Settings from "./settings";
import SignOut from "./sign-out";
import NotificationCheck from "./notification-check";
import Pencil from "./pencil";
import BulletsIcon from "./bullets";
import TextToBullets from "./text-to-bullets";
import Cancel from "./cancel";
import Globe from "./globe";
import Outline from "./outline";
import Added from "./added";
import BulletList from "./bullet-list";
import ProfileMemoTabIcon from "./profile-memo-tab-icon";
import ProfileCollectionTabIcon from "./profile-collection-tab-icon";
import Copy from "./copy";
import Calendar from "./calendar";
import Block from "./block";
import NotificationAll from "./notification-all";
import NotificationOff from "./notification-off";
import NotificationNormal from "./notification-normal";
import GlobeFill from "./globe-fill";
import Mentions from "./mentions";
import BubbleDash from "./bubble-dash";
import ChangeImage from "./change-image";
import NoBoards from "./no-boards";
import Check from "./check";
import VerifyUser from "./verify-user";
import MemoClick from "./memo-click";
import EmailUnsubscribe from "./email-unsuscribe";
import TipBox from "./tip-box";
import Analytics from "./analytics";
import CustomCheck from "./custom-check";
import LightBulb from "./light-bulb";
import AcademicCap from "./academic-cap";
import Smiley from "./smiley";
import Microphone from "./microphone";
import Wand from "./wand";
import SlidingPencil from "./sliding-pencil";
import Emoji from "./emoji";

export default {
  Analytics,
  EmailUnsubscribe,
  CustomCheck,
  MemoClick,
  VerifyUser,
  Check,
  NoBoards,
  ChangeImage,
  NotificationAll,
  NotificationOff,
  NotificationNormal,
  Memod,
  MiniMemod,
  ArrowBack,
  UserVerified,
  Like,
  Save,
  Share,
  Menu,
  Upload,
  ImageUpload,
  Zoom,
  Bullet,
  Chevron,
  Spin,
  Unsplash,
  Comments,
  Flag,
  MenuArrow,
  Search,
  UpploadBackArrow,
  Add,
  Saved,
  Close,
  Papers,
  Girl,
  Book,
  UserAvatar,
  List,
  Tag,
  Verify,
  Eye,
  Apple,
  Facebook,
  FacebookSolid,
  Twitter,
  Email,
  Loading,
  ArrowNext,
  Done,
  Submitted,
  Send,
  Youtube,
  Instagram,
  Edit,
  Trash,
  Link,
  Whatsapp,
  Linkedin,
  Reddit,
  ForwardArrow,
  Warning,
  QuestionMark,
  Lock,
  HashTag,
  ThreeDots,
  Trophy,
  ErrorIcon,
  Remove,
  Drag,
  Down,
  Plus,
  BugerMenu,
  Bell,
  SearchIcon,
  User,
  Settings,
  SignOut,
  NotificationCheck,
  Pencil,
  BulletsIcon,
  TextToBullets,
  Cancel,
  Globe,
  Outline,
  Added,
  BulletList,
  ProfileMemoTabIcon,
  ProfileCollectionTabIcon,
  Copy,
  Calendar,
  Block,
  GlobeFill,
  Mentions,
  BubbleDash,
  TipBox,
  LightBulb,
  AcademicCap,
  Smiley,
  Microphone,
  Wand,
  SlidingPencil,
  Emoji,
};
