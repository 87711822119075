export default function SignOut() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <rect width="30" height="30" fill="white" fillOpacity="0.01" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 7.5C7.17157 7.5 6.5 8.17157 6.5 9V20C6.5 20.8284 7.17157 21.5 8 21.5H11C11.2761 21.5 11.5 21.7239 11.5 22C11.5 22.2761 11.2761 22.5 11 22.5H8C6.61929 22.5 5.5 21.3807 5.5 20V9C5.5 7.61929 6.61929 6.5 8 6.5H11C11.2761 6.5 11.5 6.72386 11.5 7C11.5 7.27614 11.2761 7.5 11 7.5H8Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.696 9.35355C15.8912 9.15829 15.8912 8.84171 15.696 8.64645C15.5007 8.45118 15.1841 8.45118 14.9889 8.64645L10.1806 13.4548C9.59476 14.0406 9.59476 14.9903 10.1806 15.5761L14.9889 20.3844C15.1841 20.5797 15.5007 20.5797 15.696 20.3844C15.8912 20.1892 15.8912 19.8726 15.696 19.6773L11.0186 14.9999H22.4997C22.7758 14.9999 22.9997 14.7761 22.9997 14.4999C22.9997 14.2238 22.7758 13.9999 22.4997 13.9999H11.0496L15.696 9.35355Z"
        fill="white"
      />
    </svg>
  );
}
