interface Props {
  size?: number;
  active?: boolean;
  className?: string;
  height?: number;
}


export default function Save(props: Props) {
  const { size = 14, active, className } = props;
  const height = size / 14 * 16;

  if (active) {
    return (
      <svg width={size} height={height} className={className} viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.44434 14.6666V2.58325C1.44434 1.89289 2.06615 1.33325 2.83322 1.33325H11.1666C11.9336 1.33325 12.5554 1.89289 12.5554 2.58325V14.6666L6.99989 11.7499L1.44434 14.6666Z" fill="#05A88F" stroke="#05A88F" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    );
  }

  return (
    <svg width={size} height={height} className={className} viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.44446 14.6681V2.58472C1.44446 1.89435 2.06628 1.33472 2.83335 1.33472H11.1667C11.9338 1.33472 12.5556 1.89435 12.5556 2.58472V14.6681L7.00001 11.7514L1.44446 14.6681Z" stroke="currentColor" strokeWidth="1.4821" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}