interface Props {
  size?: number;
  className?: string;
}

export default function Like(props: Props) {
  const { size = 22, className } = props;

  return (
    <svg width={size} height={size} className={className} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5148 0.783343C8.71728 -0.336248 3.78134 1.99631 1.60073 6.41315C-0.579867 10.83 0.569586 16.1669 4.37507 19.2945C8.18055 22.4221 13.6391 22.5159 17.5498 19.521C17.8129 19.3196 17.8629 18.943 17.6614 18.6799C17.4599 18.4168 17.0833 18.3668 16.8202 18.5683C13.3522 21.2241 8.51165 21.1409 5.13699 18.3674C1.76233 15.5939 0.74301 10.8612 2.67674 6.94438C4.61046 3.02761 8.98757 0.959076 13.242 1.95194C17.4958 2.94493 20.5049 6.73743 20.5049 11.1049V12.1049C20.5049 13.4303 19.4304 14.5048 18.105 14.5048C16.7796 14.5048 15.7051 13.4303 15.7051 12.1049V7.10503C15.7051 6.77366 15.4365 6.50503 15.1051 6.50503C14.7737 6.50503 14.5051 6.77366 14.5051 7.10503V8.00687C13.6638 7.084 12.4519 6.50488 11.1048 6.50488C8.56432 6.50488 6.50488 8.56432 6.50488 11.1048C6.50488 13.6452 8.56432 15.7046 11.1048 15.7046C12.6728 15.7046 14.0575 14.9201 14.8879 13.7221C15.4803 14.8981 16.6984 15.7048 18.105 15.7048C20.0932 15.7048 21.7049 14.0931 21.7049 12.1049V11.1049C21.7049 6.17974 18.3116 1.9031 13.5148 0.783351L13.5148 0.783343ZM7.70488 11.1048C7.70488 9.22706 9.22706 7.70488 11.1048 7.70488C12.9825 7.70488 14.5046 9.22706 14.5046 11.1048C14.5046 12.9825 12.9825 14.5046 11.1048 14.5046C9.22706 14.5046 7.70488 12.9825 7.70488 11.1048Z"
        fill="currentColor"
        fillOpacity="0.6"
      />
    </svg>
  );
}