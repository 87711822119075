type Props = {
  size?: number;
  className?: string;
}

export default function Youtube(props: Props) {
  const { size = 24, className } = props;
  const height = (size / 24) * 25;

  return(
    <svg width={size} height={height} className={className} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.3458 5.46228C15.3425 5.25728 8.65333 5.25811 5.65417 5.46228C2.40667 5.68394 2.02417 7.64561 2 12.8089C2.02417 17.9631 2.40333 19.9331 5.65417 20.1556C8.65417 20.3598 15.3425 20.3606 18.3458 20.1556C21.5933 19.9339 21.9758 17.9723 22 12.8089C21.9758 7.65478 21.5967 5.68478 18.3458 5.46228ZM9.5 16.1423V9.47561L16.1667 12.8031L9.5 16.1423Z" fill="currentColor"/>
    </svg>
  );
}