export default function Bell() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.3017 21.0979V23.1415C16.3017 24.3539 15.3236 25.3377 14.1181 25.3377C12.9127 25.3377 11.9346 24.3539 11.9346 23.1415V21.0979"
        stroke="white"
      />
      <path
        d="M23.0357 19.9853C22.7691 19.6638 22.268 19.3188 21.6931 18.6401C20.6752 17.4373 20.109 15.4388 20.109 13.7276C20.109 13.7276 20.1116 11.585 20.0968 10.8713C20.043 8.2273 18.3598 6.04446 15.962 5.31947H15.9194C15.7744 5.26881 15.625 5.22513 15.4695 5.18583V4.80149C15.4695 4.08261 14.8903 3.5 14.1764 3.5C13.4616 3.5 12.8823 4.08261 12.8823 4.80149V5.1736C12.7078 5.21465 12.5393 5.26269 12.3769 5.31947H12.343C9.946 6.04446 8.26201 8.2273 8.20817 10.8713C8.1934 11.585 8.19601 13.7276 8.19601 13.7276C8.19601 15.4388 7.63063 17.4373 6.6119 18.6401C5.90148 19.4795 5.29876 19.8106 5.12246 20.215C5.05124 20.3399 5 20.477 5 20.6317C5 21.0404 5.29007 21.3663 5.67047 21.4527C5.72084 21.4702 5.77295 21.4807 5.82766 21.4842C5.83635 21.4842 5.84416 21.4894 5.85372 21.4894L5.85719 21.4885L5.8624 21.4894H14.2276H14.9458H22.4426C22.4495 21.4894 22.4556 21.4842 22.4634 21.4842C22.473 21.4842 22.4799 21.4894 22.4895 21.4894C22.961 21.4894 23.3432 21.1051 23.3432 20.6317C23.3432 20.3687 23.2199 20.1434 23.0357 19.9853Z"
        stroke="white"
      />
    </svg>
  );
}
