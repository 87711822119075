interface Props {
  size?: number;
  className?: string;
}

export default function UserVerified(props: Props) {
  const { size = 10, className } = props;

  return (
    <svg width={size} height={size + 1} viewBox="0 0 10 11" className={className} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.91396 0.800049C7.51744 0.800049 9.62797 2.91058 9.62797 5.51406C9.62797 8.11754 7.51744 10.2281 4.91396 10.2281C2.31049 10.2281 0.199951 8.11754 0.199951 5.51406C0.199951 2.91058 2.31049 0.800049 4.91396 0.800049Z" fill="#05A88F"/>
    <path d="M3.48511 5.53567L4.57296 6.44221L6.92997 4.08521" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}