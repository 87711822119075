interface Props {
  className?: string;
  size?: string; 
}


export default function Remove(props: Props) {
  const { className, size = 24 } = props;
  return (
    <svg width={size} height={size} className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 0C5.38254 0 0 5.38254 0 12C0 18.6162 5.38254 24 12 24C18.6162 24 24 18.6162 24 12C24 5.38254 18.6162 0 12 0Z" fill="white" />
      <path d="M6.34326 12L17.657 12" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}