type Props = {
  size?: number;
  className?: string;
}

export default function Twitter(props: Props) {
  const { size = 24, className } = props;

  return (
    <svg width={size} height={size} className={className} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22 5.924a8.192 8.192 0 01-2.357.646 4.11 4.11 0 001.805-2.27 8.22 8.22 0 01-2.606.996A4.096 4.096 0 0015.847 4c-2.65 0-4.596 2.472-3.998 5.037a11.648 11.648 0 01-8.457-4.286 4.109 4.109 0 001.27 5.478 4.087 4.087 0 01-1.858-.513c-.045 1.9 1.318 3.679 3.291 4.075a4.111 4.111 0 01-1.853.07 4.106 4.106 0 003.833 2.849A8.25 8.25 0 012 18.41a11.616 11.616 0 006.29 1.843c7.618 0 11.922-6.434 11.663-12.205A8.353 8.353 0 0022 5.924z" fill="currentColor" />
    </svg>
  );
}