interface Props {
  size?: number;
}

export default function Whatsapp(props: Props) {
  const { size = 28 } = props;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.6539 8.33007C18.2552 6.93889 16.3922 6.113 14.4222 6.01077C12.4522 5.90853 10.5137 6.53715 8.97855 7.77609C7.44341 9.01502 6.41962 10.7771 6.1035 12.7243C5.78738 14.6716 6.20119 16.667 7.26557 18.328L6.59639 19.8895C6.50799 20.0991 6.4841 20.3303 6.52777 20.5535C6.57143 20.7768 6.68068 20.982 6.84153 21.1428C7.00239 21.3037 7.20756 21.413 7.43082 21.4566C7.65407 21.5003 7.88528 21.4764 8.09489 21.388L9.6563 20.7188C10.9484 21.5534 12.4535 21.9982 13.9916 22C14.2605 22 14.5293 22 14.7981 21.96C16.3055 21.8076 17.7388 21.2306 18.9315 20.2961C20.1241 19.3615 21.0271 18.1078 21.5356 16.6805C22.0441 15.2532 22.1372 13.7109 21.8041 12.2327C21.471 10.7546 20.7254 9.40131 19.6539 8.33007ZM20.7978 14.8733C20.5999 16.4066 19.8903 17.8282 18.7837 18.9078C17.6771 19.9874 16.2385 20.6618 14.7008 20.8218C13.0539 20.9996 11.3991 20.566 10.0509 19.6035C9.95371 19.5356 9.83778 19.4996 9.71921 19.5005C9.64254 19.5007 9.56669 19.5162 9.49615 19.5463L7.64305 20.3413L8.43805 18.4881C8.47985 18.3962 8.49657 18.2949 8.48653 18.1945C8.47648 18.094 8.44002 17.998 8.38086 17.9162C7.4208 16.5668 6.98749 14.9129 7.16262 13.2661C7.37574 11.4579 8.29841 9.80845 9.72766 8.68057C11.1569 7.55269 12.9756 7.03879 14.7838 7.25192C16.5919 7.46505 18.2413 8.38775 19.3692 9.81704C20.497 11.2463 21.0109 13.0651 20.7978 14.8733Z"
        fill="white"
      />
      <path
        d="M17.0858 14.7417C16.8734 14.5406 16.592 14.4285 16.2994 14.4285C16.0069 14.4285 15.7254 14.5406 15.513 14.7417L15.2556 14.9991C14.7888 14.7251 14.3632 14.3861 13.9916 13.9925C13.5916 13.6294 13.2449 13.2116 12.9621 12.7513L13.2195 12.4939C13.3292 12.3934 13.4173 12.2716 13.4783 12.1359C13.5392 12.0001 13.5719 11.8534 13.5741 11.7046C13.5741 11.5576 13.5448 11.412 13.4879 11.2765C13.4309 11.1409 13.3474 11.0181 13.2424 10.9153L12.0985 9.77138C11.8855 9.56689 11.6016 9.4527 11.3063 9.4527C11.0111 9.4527 10.7272 9.56689 10.5142 9.77138C9.92386 10.3611 9.56862 11.1457 9.51493 11.9785C9.46124 12.8112 9.71278 13.6349 10.2225 14.2956C10.7379 14.9405 11.2898 15.5555 11.8754 16.1373C12.4573 16.723 13.0722 17.2749 13.7171 17.7903C14.3128 18.2571 15.0478 18.5108 15.8047 18.511C16.2589 18.5113 16.7086 18.4215 17.1278 18.2468C17.5471 18.072 17.9274 17.8158 18.2469 17.4929C18.4497 17.2801 18.5628 16.9975 18.5628 16.7036C18.5628 16.4097 18.4497 16.127 18.2469 15.9143L17.0858 14.7417ZM14.4206 16.8523C13.818 16.3644 13.245 15.8411 12.7047 15.2851C12.1487 14.7448 11.6254 14.1718 11.1376 13.5692C10.7932 13.1354 10.6179 12.5914 10.6442 12.0381C10.6705 11.4848 10.8966 10.9599 11.2806 10.5607L12.4245 11.7046L12.0985 12.0249C11.9566 12.1311 11.8589 12.2859 11.8239 12.4596C11.7038 13.0316 12.1156 13.7008 13.208 14.7989C14.3005 15.8971 14.9982 16.2803 15.5416 16.1659C15.7246 16.1281 15.8863 16.0217 15.9934 15.8685L16.2794 15.5482L17.4233 16.6921C17.0266 17.0771 16.5039 17.3054 15.9519 17.3348C15.3999 17.3643 14.856 17.1929 14.4206 16.8523Z"
        fill="white"
      />
    </svg>
  );
}
