import classNames from "classnames";

interface Props {
  className?: string;
  size?: number;
  height?: number;
}

export default function Trash(props: Props) {
  const { size = 18 } = props;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(props.className)}
    >
      <rect width="18" height="18" fill="white" fillOpacity="0.01" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.97642 2.1001C7.14799 2.1001 6.47642 2.77167 6.47642 3.6001V3.9001H4.87068H4.87058H3.60005C3.43436 3.9001 3.30005 4.03441 3.30005 4.2001C3.30005 4.36578 3.43436 4.5001 3.60005 4.5001H4.58631L5.09876 13.8819C5.14221 14.6774 5.79989 15.3001 6.59653 15.3001H11.403C12.1997 15.3001 12.8574 14.6774 12.9008 13.8819L13.4133 4.5001H14.4C14.5657 4.5001 14.7 4.36578 14.7 4.2001C14.7 4.03441 14.5657 3.9001 14.4 3.9001H13.129H13.1289H11.5235V3.6001C11.5235 2.77167 10.8519 2.1001 10.0235 2.1001H7.97642ZM5.69787 13.8492L5.1872 4.5001H6.77642H11.2235H12.8124L12.3017 13.8492C12.2756 14.3265 11.881 14.7001 11.403 14.7001H6.59653C6.11854 14.7001 5.72394 14.3265 5.69787 13.8492ZM10.9235 3.9001V3.6001C10.9235 3.10304 10.5205 2.7001 10.0235 2.7001H7.97642C7.47936 2.7001 7.07642 3.10304 7.07642 3.6001V3.9001H10.9235ZM9.50005 5.7001C9.50005 5.42396 9.27619 5.2001 9.00005 5.2001C8.72391 5.2001 8.50005 5.42396 8.50005 5.7001L8.50005 13.5001C8.50005 13.7762 8.72391 14.0001 9.00005 14.0001C9.27619 14.0001 9.50005 13.7762 9.50005 13.5001L9.50005 5.7001ZM11.244 5.20051C11.5199 5.21175 11.7345 5.44453 11.7233 5.72044L11.4056 13.5204C11.3944 13.7964 11.1616 14.0109 10.8857 13.9997C10.6098 13.9884 10.3952 13.7557 10.4064 13.4798L10.7241 5.67975C10.7353 5.40384 10.9681 5.18928 11.244 5.20051ZM6.27683 5.72044C6.26559 5.44453 6.48016 5.21175 6.75607 5.20051C7.03198 5.18928 7.26477 5.40384 7.276 5.67975L7.59365 13.4798C7.60489 13.7557 7.39032 13.9884 7.11441 13.9997C6.8385 14.0109 6.60571 13.7964 6.59448 13.5204L6.27683 5.72044Z"
        fill="currentColor"
      />
    </svg>
  );
}
