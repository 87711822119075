interface Props {
  size?: number;
}

export default function ArrowBack({ size = 26 }: Props) {
  return (
    <svg
      width={size + 1}
      height={size}
      viewBox="0 0 27 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.890991"
        y="0.265381"
        width="25.4694"
        height="25.4694"
        fill="#C4C4C4"
        fillOpacity="0.01"
      />
      <path
        d="M17.0542 4.18359L8.72766 12.5101L17.0542 20.8367"
        stroke="white"
        strokeWidth="0.979592"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
