export default function TextToBullets() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" fill="white" fillOpacity="0.01" />
      <path
        d="M4.2666 17.0667V25.6C4.2666 26.7782 5.22173 27.7333 6.39993 27.7333H22.3999"
        stroke="white"
        strokeLinecap="round"
      />
      <path
        d="M20.2666 29.8667L22.3999 27.7334"
        stroke="white"
        strokeLinecap="round"
      />
      <path
        d="M22.3999 27.7334L20.2666 25.6001"
        stroke="white"
        strokeLinecap="round"
      />
      <path
        d="M27.7334 14.9333V6.39993C27.7334 5.22173 26.7783 4.2666 25.6001 4.2666H9.6001"
        stroke="white"
        strokeLinecap="round"
      />
      <path
        d="M11.7334 2.1333L9.6001 4.26663"
        stroke="white"
        strokeLinecap="round"
      />
      <path
        d="M9.6001 4.2666L11.7334 6.39993"
        stroke="white"
        strokeLinecap="round"
      />
      <path d="M11.2002 20.2666H20.8002" stroke="white" strokeLinecap="round" />
      <path d="M9.6001 16H22.4001" stroke="white" strokeLinecap="round" />
      <path d="M12.7998 11.7334H19.1998" stroke="white" strokeLinecap="round" />
    </svg>
  );
}
