interface Props {
  size?: number;
  className?: string;
}

export default function UserAvatar(props: Props) {
  const { size = 64, className } = props;

  return (
    <svg width={size} height={size} className={className} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M31.7142 31.8853C37.2712 31.8853 41.7761 27.3804 41.7761 21.8234C41.7761 16.2663 37.2712 11.7614 31.7142 11.7614C26.1571 11.7614 21.6522 16.2663 21.6522 21.8234C21.6522 27.3804 26.1571 31.8853 31.7142 31.8853Z" stroke="currentColor" strokeWidth="2.13" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M49 52.0085C49 44.2206 41.2523 37.9218 31.7136 37.9218C22.1749 37.9218 14.4272 44.2206 14.4272 52.0085" stroke="currentColor" strokeWidth="2.13" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}