export default function User() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      className="mr-1"
    >
      <path
        fill="#aeb3b6"
        d="M10 12.5c-5.92 0-9 3.5-9 5.5v1h18v-1c0-2-3.08-5.5-9-5.5z"
      />
      <circle fill="#aeb3b6" cx="10" cy="6" r="5" />
    </svg>
  );
}
