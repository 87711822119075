import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {
  size?: string | number;
}

export default function LightBulb({ size = 16, ...props }: Props) {
  return (
    <svg width={size} height={size} {...props} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.00065 14C6.00065 14.3667 6.30065 14.6667 6.66732 14.6667H9.33398C9.70065 14.6667 10.0007 14.3667 10.0007 14V13.3334H6.00065V14ZM8.00065 1.33337C5.42732 1.33337 3.33398 3.42671 3.33398 6.00004C3.33398 7.58671 4.12732 8.98004 5.33398 9.82671V11.3334C5.33398 11.7 5.63398 12 6.00065 12H10.0007C10.3673 12 10.6673 11.7 10.6673 11.3334V9.82671C11.874 8.98004 12.6673 7.58671 12.6673 6.00004C12.6673 3.42671 10.574 1.33337 8.00065 1.33337ZM9.90065 8.73337L9.33398 9.13337V10.6667H6.66732V9.13337L6.10065 8.73337C5.20065 8.10671 4.66732 7.08671 4.66732 6.00004C4.66732 4.16004 6.16065 2.66671 8.00065 2.66671C9.84065 2.66671 11.334 4.16004 11.334 6.00004C11.334 7.08671 10.8007 8.10671 9.90065 8.73337Z" fill="currentColor" />
    </svg>
  );
}