interface Props {
  size?: number;
  className?: string;
  active?: boolean;
  height?: number;
}

export default function Hashtag(props: Props) {
  const { size = 22, className, height = 22 } = props;

  if (props.active) {
    return (
      <svg width={size} height={height} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M22.9105 0L21.8325 5.537H25.3605V8.918H21.1955L19.7745 16.366H23.8905V19.747H19.0885L18.0105 25.529H14.2375L15.3155 19.747H7.37746L6.29946 25.529H2.52646L3.60446 19.747H0.272461V16.366H4.29046L5.71146 8.918H1.74246V5.537H6.34846L7.42646 0H11.1995L10.1215 5.537H18.0595L19.1375 0H22.9105ZM8.06345 16.366H16.0014L17.4224 8.91797H9.48445L8.06345 16.366Z" fill="white" fillOpacity="0.8" />
    </svg>
    );
  }

  return(
    <svg width="22" height="22" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M22.9105 0L21.8325 5.537H25.3605V8.918H21.1955L19.7745 16.366H23.8905V19.747H19.0885L18.0105 25.529H14.2375L15.3155 19.747H7.37746L6.29946 25.529H2.52646L3.60446 19.747H0.272461V16.366H4.29046L5.71146 8.918H1.74246V5.537H6.34846L7.42646 0H11.1995L10.1215 5.537H18.0595L19.1375 0H22.9105ZM8.06345 16.366H16.0014L17.4224 8.91797H9.48445L8.06345 16.366Z" fill="white" fillOpacity="0.8" />
    </svg>
  );
}