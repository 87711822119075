interface Props {
  size?: number;
  className?: string;
}

export default function Flag(props: Props) {
  const { size = 24, className} = props;

  return (
    <svg width={size} className={className} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M6.4001 4.3937V12.3135C7.8402 12.0383 10.2843 11.6731 12.5266 12.4205C13.4186 12.7178 14.5617 12.7862 15.6533 12.7403C16.5624 12.7021 17.4093 12.5859 18.0001 12.4737V4.50851C17.2672 4.68014 16.3799 4.8599 15.4806 4.95968C14.3767 5.08217 13.2062 5.09033 12.2736 4.77946C10.5056 4.19014 8.76605 4.27906 7.29171 4.35442C6.98208 4.37025 6.68414 4.38548 6.4001 4.3937ZM6.4001 13.1285C7.80571 12.8547 10.1593 12.4747 12.2736 13.1795C13.3016 13.5221 14.5585 13.5871 15.6869 13.5396C16.8216 13.4919 17.865 13.3287 18.4869 13.1905C18.6699 13.1498 18.8001 12.9875 18.8001 12.8V3.99999C18.8001 3.87682 18.7433 3.76051 18.6463 3.68471C18.5492 3.60891 18.4226 3.58206 18.3031 3.61193C17.5095 3.81033 16.4539 4.04678 15.3924 4.16456C14.3205 4.2835 13.291 4.27533 12.5266 4.02052C10.5983 3.37777 8.64061 3.47988 7.15336 3.55745C6.72618 3.57973 6.33781 3.59999 6.0001 3.59999C5.77918 3.59999 5.6001 3.77908 5.6001 3.99999V20C5.6001 20.2209 5.77918 20.4 6.0001 20.4C6.22101 20.4 6.4001 20.2209 6.4001 20V13.1285Z" fill="currentColor"/>
    </svg>

  );
}
