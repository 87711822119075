export default function QuestionMark() {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1353_163021)">
        <rect
          x="0.5"
          y="0.5"
          width="30"
          height="30"
          fill="white"
          fillOpacity="0.01"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.4993 16.8332C13.9972 16.8332 16.8327 13.9976 16.8327 10.4998C16.8327 7.00203 13.9972 4.1665 10.4993 4.1665C7.00155 4.1665 4.16602 7.00203 4.16602 10.4998C4.16602 13.9976 7.00155 16.8332 10.4993 16.8332ZM10.4993 17.8332C6.44926 17.8332 3.16602 14.5499 3.16602 10.4998C3.16602 6.44975 6.44926 3.1665 10.4993 3.1665C14.5494 3.1665 17.8327 6.44975 17.8327 10.4998C17.8327 14.5499 14.5494 17.8332 10.4993 17.8332ZM8.01935 8.67127C8.06833 7.43589 8.91187 6.49984 10.4901 6.49984C11.9268 6.49984 12.8955 7.35426 12.8955 8.51889C12.8955 9.36242 12.471 9.95562 11.7527 10.3856C11.0506 10.7992 10.8493 11.0876 10.8493 11.6481V11.9801H9.72275L9.71731 11.5447C9.6901 10.7774 10.0221 10.2876 10.7676 9.84133C11.4262 9.44406 11.6602 9.12841 11.6602 8.56786C11.6602 7.9529 11.1758 7.5012 10.4302 7.5012C9.67921 7.5012 9.19486 7.9529 9.14588 8.67127H8.01935ZM10.2943 14.4999C9.897 14.4999 9.58135 14.1952 9.58135 13.7979C9.58135 13.4006 9.897 13.0958 10.2943 13.0958C10.7024 13.0958 11.0126 13.4006 11.0126 13.7979C11.0126 14.1952 10.7024 14.4999 10.2943 14.4999Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1353_163021">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.5 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
