interface Props {
  size?: number;
  className?: string;
}

export default function Like(props: Props) {
  const { size = 24, className } = props;

  return (
    <svg width={size} height={size} className={className} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.8457 9.5C7.56956 9.5 7.3457 9.72386 7.3457 10C7.3457 10.2761 7.56956 10.5 7.8457 10.5V9.5ZM17.8457 10.5C18.1218 10.5 18.3457 10.2761 18.3457 10C18.3457 9.72386 18.1218 9.5 17.8457 9.5V10.5ZM7.8457 10.5H12.8457V9.5H7.8457V10.5ZM12.8457 10.5H17.8457V9.5H12.8457V10.5Z"
        fill="currentColor"
      />
      <path
        d="M1.3457 14.5V4.5C1.3457 2.29086 3.13656 0.5 5.3457 0.5H20.3457C22.5548 0.5 24.3457 2.29086 24.3457 4.5V14.5C24.3457 16.7091 22.5548 18.5 20.3457 18.5H16.3457L12.8457 23.5L9.3457 18.5H5.3457C3.13656 18.5 1.3457 16.7091 1.3457 14.5Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}