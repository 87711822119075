interface Props {
  size?: number;
  className?: string;
}


export default function Saved(props: Props) {
  const { size = 14, className } = props;

  return (
    <svg width={size} height="11" className={className} viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.639999 5.12098L4.88 9.36098L13.36 0.880981" stroke="currentColor" strokeLinecap="round"/>
    </svg>

  );
}