interface Props {
  fillColor?: string;
  strokeColor?: string;
  size?: number;
}

export default function VerifyUser(props: Props) {
  const { fillColor = "#05A88F", strokeColor = "#FFFFFF", size = 30 } = props;
  return(
    <svg width={size} height={size} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill={fillColor} d="M14.9935 1.10059C22.8374 1.10059 29.196 7.45925 29.196 15.3031C29.196 23.1469 22.8374 29.5055 14.9935 29.5055C7.14968 29.5055 0.791016 23.1469 0.791016 15.3031C0.791016 7.45925 7.14968 1.10059 14.9935 1.10059Z" />
      <path stroke={strokeColor} d="M9.54492 16.643L12.8224 19.3742L19.9237 12.2729" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}