import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {
  size?: string | number;
}

export default function Disappointed(props: Props) {
  const { size = 40 } = props;

  return (
    <svg width={size} height={size} {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20 0C8.97733 0 0 8.97733 0 20C0 31.0227 8.97733 40 20 40C31.0227 40 40 31.0227 40 20C40 8.97733 31.0227 0 20 0ZM20 4C28.8613 4 36 11.1387 36 20C36 28.8613 28.8613 36 20 36C11.1387 36 4 28.8613 4 20C4 11.1387 11.1387 4 20 4ZM14.2109 17.1055C13.3302 18.867 12.7456 19.3494 12.0078 19.6445C11.2701 19.9396 10 20 8 20V24C10 24 11.7299 24.0604 13.4922 23.3555C15.2545 22.6506 16.6698 21.133 17.7891 18.8945L14.2109 17.1055ZM25.7891 17.1055L22.2109 18.8945C23.4495 21.3716 25.4877 22.7719 27.3672 23.3984C29.2467 24.0249 31 24 32 24V20C31 20 29.7533 19.9751 28.6328 19.6016C27.5123 19.2281 26.5505 18.6284 25.7891 17.1055ZM19.9688 26C14.8208 26.014 13.9844 31.082 13.9844 31.082C13.9844 31.082 16.5384 30.002 19.9844 30C23.4044 29.998 26 31.0508 26 31.0508C26 31.0508 25.1167 25.986 19.9688 26Z" fill="currentColor"/>
    </svg>
  );
}