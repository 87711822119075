import { SaveMemoItem } from "@components/molecules/save-memo-popover";
import Icon from "@components/atoms/icon";
import { translate } from "@locales";
import { atom } from "jotai";

export const ITEMBOARD: SaveMemoItem[] = [
  {
    Icon: Icon.Add,
    text: translate("base.save-board"),
    key: "save",
  },
];

export const UserSaveItemsAtom = atom<SaveMemoItem[]>(ITEMBOARD);
