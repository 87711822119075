interface Props {
  size?: number
}

export default function Block(props: Props) {
  const { size = 26 } = props;

  return(
    <svg width={size} height={size} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.2282 4.52352C7.27491 2.64596 10.0036 1.5 13 1.5C19.3513 1.5 24.5 6.64873 24.5 13C24.5 16.0714 23.2959 18.8616 21.334 20.9243L5.2282 4.52352ZM4.52117 5.23076C2.64501 7.27718 1.5 10.0049 1.5 13C1.5 19.3513 6.64873 24.5 13 24.5C15.9201 24.5 18.586 23.4116 20.6142 21.6184L4.52117 5.23076ZM13 0.5C6.09644 0.5 0.5 6.09644 0.5 13C0.5 19.9036 6.09644 25.5 13 25.5C19.9036 25.5 25.5 19.9036 25.5 13C25.5 6.09644 19.9036 0.5 13 0.5Z"
        fill="white"
      />
    </svg>
  );
}