interface Props {
  size?: number;
  className?: string;
}

export function Facebook(props: Props) {
  const { size = 12, className } = props;
  return (
    <svg width={size} height="21" className={className} fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.167 7.667H12V11.5H8.167v8.944H4.333V11.5H.5V7.667h3.833V6.063c0-1.52.478-3.438 1.429-4.487C6.712.523 7.9 0 9.322 0H12v3.833H9.317c-.637 0-1.15.514-1.15 1.15v2.684z" fill="#fff"></path></svg>
  );
}

export function FacebookSolid(props: Props) {
  const { size = 24, className } = props;
  const height = (size / 24) * 25;

  return (
    <svg width={size} height={height} className={className} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 2.30859C3.79086 2.30859 2 4.09945 2 6.30859V18.3086C2 20.5177 3.79086 22.3086 6 22.3086H11.8808V15.4664H9.516V12.7999H11.8808V10.8335C11.8808 8.55129 13.3119 7.30859 15.403 7.30859C16.4047 7.30859 17.265 7.38148 17.516 7.41387V9.79915L16.066 9.79988C14.929 9.79988 14.709 10.3263 14.709 11.0978V12.8006H17.4207L17.0677 15.4671H14.709V22.3086H18C20.2091 22.3086 22 20.5177 22 18.3086V6.30859C22 4.09945 20.2091 2.30859 18 2.30859H6Z" fill="currentColor" />
    </svg>
  );
}