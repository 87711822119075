import classNames from "classnames";

interface Props {
  size?: number;
  className?: string;
}

export default function Copy(props: Props) {
  const { size = 18 } = props;
  const height = size / 18 * 19;

  return (
    <svg width={size} height={height} className={classNames(props.className)} viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.8358 12.61H14.3399C14.6205 12.61 14.8481 12.3825 14.8481 12.1019V4.03649C14.8481 3.75584 14.6205 3.52832 14.3399 3.52832H6.7786C6.49794 3.52832 6.27042 3.75584 6.27042 4.03649V4.54058H5.27042V4.03649C5.27042 3.20355 5.94566 2.52832 6.7786 2.52832H14.3399C15.1728 2.52832 15.8481 3.20355 15.8481 4.03649V12.1019C15.8481 12.9348 15.1728 13.61 14.3399 13.61H13.8358V12.61ZM5.25098 5.04875C3.87026 5.04875 2.75098 6.16804 2.75098 7.54875V14.1346C2.75098 15.5153 3.87026 16.6346 5.25098 16.6346H10.8286C12.2093 16.6346 13.3286 15.5153 13.3286 14.1346V7.54875C13.3286 6.16804 12.2093 5.04875 10.8286 5.04875H5.25098ZM3.75098 7.54875C3.75098 6.72032 4.42255 6.04875 5.25098 6.04875H10.8286C11.657 6.04875 12.3286 6.72032 12.3286 7.54875V14.1346C12.3286 14.963 11.657 15.6346 10.8286 15.6346H5.25098C4.42255 15.6346 3.75098 14.963 3.75098 14.1346V7.54875Z" fill="currentColor" />
    </svg>
  );
}
