export default function BulletList() {
  return(
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse opacity="0.15" cx="12.1831" cy="12.1851" rx="11.1831" ry="11.1851" fill="#0E8775" />
      <g filter="url(#filter0_d)">
        <ellipse cx="12.1831" cy="12.1851" rx="4.02592" ry="4.02663" fill="#0E8775" />
      </g>
      <defs>
        <filter id="filter0_d" x="0.157227" y="0.158447" width="24.0518" height="24.0533" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.6875 0 0 0 0 0.585227 0 0 0 1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}