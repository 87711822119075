interface Props {
  className?: string;
  size?: number;
  heigth?: number;
}

export default function Plus(props: Props) {
  const { className, size = 12, heigth = 12 } = props;

  return(
    <svg width={size} className={className} height={heigth} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M6.59961 1.19961C6.59961 0.868239 6.33098 0.599609 5.99961 0.599609C5.66824 0.599609 5.39961 0.868239 5.39961 1.19961V5.39961H1.19961C0.868239 5.39961 0.599609 5.66824 0.599609 5.99961C0.599609 6.33098 0.868239 6.59961 1.19961 6.59961H5.39961V10.7996C5.39961 11.131 5.66824 11.3996 5.99961 11.3996C6.33098 11.3996 6.59961 11.131 6.59961 10.7996V6.59961H10.7996C11.131 6.59961 11.3996 6.33098 11.3996 5.99961C11.3996 5.66824 11.131 5.39961 10.7996 5.39961H6.59961V1.19961Z" fill="white" />
    </svg>
  );
}