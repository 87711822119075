interface Props {
  size?: number;
  className?: string;
}

export default function Apple(props: Props) {
  const { size = 12, className } = props;
  const height = (size / 12) * 14;

  return (
    <svg width={size} height={height} className={className} viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.51568 3.74502C7.43106 3.74502 6.85523 4.28743 6.00311 4.27637C5.15076 4.28743 4.57513 3.74502 3.49036 3.74502C2.62698 3.74502 1.53096 4.27637 1.03292 5.05144C0.114238 6.46863 0.202715 8.00769 0.590166 9.60168C1.0108 11.296 2.49407 13.3774 3.66749 13.3774C4.6304 13.3774 5.06229 12.8568 6.00311 12.8568C6.94368 12.8568 7.36434 13.3774 8.31658 13.3774C9.40118 13.3774 10.6963 11.5947 11.25 10.0113C10.1871 9.59062 9.46751 8.53901 9.46751 7.42068C9.46751 6.3136 10.0323 5.46111 10.8957 4.97402C10.3645 4.25425 9.34588 3.74502 8.51568 3.74502ZM8.22774 0.877441C6.66724 1.04355 5.50506 2.34993 5.60459 3.83352C7.16529 3.67849 8.31658 2.36101 8.22774 0.877441Z" fill="currentColor" />
    </svg>
  );
}
