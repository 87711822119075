interface Props {
  size?: number;
}

export default function CustomCheck(props: Props) {
  const { size = 25 } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height="24" viewBox="0 0 25 24" fill="none">
      <rect x="0.5" width="24" height="24" fill="white" fillOpacity="0.01" />
      <path d="M7.41211 12.0968L10.8041 15.4888L17.5881 8.70483" stroke="white" strokeLinecap="round" />
    </svg>
  );
}