import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {
  size?: string | number;
}

export default function Microphone(props: Props) {
  const { size = 24 } = props;

  return (
    <svg width={size} height={size} {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.0332 3.00011C13.3282 2.98211 11.1345 5.11718 11.0215 7.78918L2.23633 18.3634C1.90633 18.7604 1.93383 19.3441 2.29883 19.7091L4.29101 21.7013C4.65601 22.0663 5.23972 22.0947 5.63672 21.7657L16.2344 12.9767C18.8974 12.8507 21.02 10.661 21 7.963C20.98 5.29 18.7062 3.01811 16.0332 3.00011ZM16 5.00011C17.654 5.00011 19 6.34611 19 8.00011C19 9.59411 17.7436 10.9036 16.1406 10.9786L15.4727 11.0099L15.4453 11.0314L12.9785 8.56457L12.9902 8.54894L13.0195 7.87511C13.0875 6.26211 14.397 5.00011 16 5.00011ZM11.6953 10.1095L13.9004 12.3146L5.06054 19.6446L4.35742 18.9396L11.6953 10.1095Z" fill="currentColor" />
    </svg>
  );
}