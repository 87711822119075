import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { translate } from "src/locales";

const DSN = process.env.PUBLIC_NEXT_SENTRY_DSN as string;

export default function useSentry() {
  const initialize = () => {
    if (!DSN) {
      // console.error(translate('sentry.dsn-missing'));
    }

    Sentry.init({
      dsn: DSN,
      integrations: [new BrowserTracing()],
    
      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 1.0,
    });
  }

  const report = (e: Error) => Sentry.captureException(e);

  return {
    operations: {
      initialize,
      report,
    }
  };
}