interface Props {
  size?: number;
  height?: number;
  className?: string;
  onClick?: VoidFunction;
}

export default function Close(props: Props) {
  const { size = 14, className, onClick } = props;

  return (
    <svg width={size} height={size} className={className} onClick={onClick} viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.24264 6.99987L0.292893 2.05012C-0.0976311 1.6596 -0.0976311 1.02644 0.292893 0.635911C0.683417 0.245387 1.31658 0.245387 1.70711 0.635911L6.65685 5.58566L11.6066 0.635911C11.9971 0.245387 12.6303 0.245387 13.0208 0.635911C13.4113 1.02644 13.4113 1.6596 13.0208 2.05012L8.07107 6.99987L13.0208 11.9496C13.4113 12.3401 13.4113 12.9733 13.0208 13.3638C12.6303 13.7544 11.9971 13.7544 11.6066 13.3638L6.65685 8.41409L1.70711 13.3638C1.31658 13.7544 0.683417 13.7544 0.292893 13.3638C-0.0976311 12.9733 -0.0976311 12.3401 0.292893 11.9496L5.24264 6.99987Z" fill="currentColor" />
    </svg>
  );
}
