interface Props {
  size?: number;
  height?: number;
  className?: string;
  fill?: string;
  stroke?: string
}

export default function Lock(props: Props) {
  const { size = 21, className, fill, stroke = "white" } = props;
  const height = size / 21 * 20;

  return (
    <svg
      width={size}
      height={height}
      viewBox="0 0 21 20"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill="none"
    >
      <path
        d="M6.24168 8.67406H5.40002C4.46334 8.67406 3.70312 9.37221 3.70312 10.2255V16.4313C3.70312 17.2846 4.46334 17.9828 5.40002 17.9828H15.5814C16.5181 17.9828 17.2783 17.2846 17.2783 16.4313V10.2255C17.2783 9.37221 16.5181 8.67406 15.5814 8.67406H14.7126M6.24168 8.67406V6.34688C6.24168 4.21364 8.14221 2.46826 10.4771 2.46826C12.8121 2.46826 14.7126 4.21364 14.7126 6.34688V8.67406M6.24168 8.67406H14.7126"
        stroke={stroke}
        strokeWidth="2.0252"
        strokeMiterlimit="10"
        fill={fill}
      />
    </svg>
  );
}
