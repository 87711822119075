interface Props {
  size?: number;
  active?: boolean;
  className?: string;
  height?: number;
}


export default function ArrowNext(props: Props) {
  const { size = 11, active, className, height = 20 } = props;

  if (active) {
    return (
      <svg
        width={size}
        height={height}
        viewBox="0 0 11 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.562103 0.765625L9.61536 9.81888L0.562103 18.8721"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="#05A88F"
        />
      </svg>
    );
  }

  return (
    <svg
      width={size}
      height={height}
      viewBox="0 0 11 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.562103 0.765625L9.61536 9.81888L0.562103 18.8721"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
