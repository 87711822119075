import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {
  size?: string | number;
}

export default function VeryHappy(props: Props) {
  const { size = 40 } = props;

  return (
    <svg width={size} height={size} {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20 0C8.954 0 0 8.954 0 20C0 31.046 8.954 40 20 40C31.046 40 40 31.046 40 20C40 8.954 31.046 0 20 0ZM20 4C28.836 4 36 11.164 36 20C36 28.836 28.836 36 20 36C11.164 36 4 28.836 4 20C4 11.164 11.164 4 20 4ZM14 10C11.79 10 10 11.79 10 14H18C18 11.79 16.21 10 14 10ZM26 10C23.79 10 22 11.79 22 14H30C30 11.79 28.21 10 26 10ZM8 20C8 26.64 13.36 32 20 32C26.64 32 32 26.64 32 20H8Z" fill="currentColor"/>
    </svg>
  );
}