interface Props {
  size?: number;
  fill?: string;
}

export default function Edit(props: Props) {
  const { size = 18, fill = "white" } = props;

  return (
    <svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="18" height="18" fill="white" fillOpacity="0.01" />
      <path fill={fill} fillRule="evenodd" clipRule="evenodd" d="M3.43646 4.4999C3.02978 4.4999 2.7001 4.82958 2.7001 5.23627V14.5635C2.7001 14.9702 3.02978 15.2999 3.43646 15.2999H12.7637C13.1704 15.2999 13.5001 14.9702 13.5001 14.5635V8.6999C13.5001 8.53422 13.6344 8.3999 13.8001 8.3999C13.9658 8.3999 14.1001 8.53422 14.1001 8.6999V14.5635C14.1001 15.3016 13.5018 15.8999 12.7637 15.8999H3.43646C2.69841 15.8999 2.1001 15.3016 2.1001 14.5635V5.23627C2.1001 4.49821 2.69841 3.8999 3.43646 3.8999H8.61828C8.78396 3.8999 8.91828 4.03422 8.91828 4.1999C8.91828 4.36559 8.78396 4.4999 8.61828 4.4999H3.43646Z" />
      <path fill={fill} fillRule="evenodd" clipRule="evenodd" d="M11.9806 3.8999C12.0602 3.89992 12.1365 3.93155 12.1928 3.98784L13.4123 5.20813C13.5294 5.32531 13.5293 5.51522 13.4122 5.63235L8.37475 10.6687C8.337 10.7065 8.28985 10.7335 8.23818 10.7469L6.94565 11.0832C6.94558 11.0832 6.9455 11.0832 6.94543 11.0833C6.7684 11.1294 6.58025 11.0782 6.45097 10.9488L6.66317 10.7368L6.45096 10.9488C6.3217 10.8195 6.27065 10.6314 6.31668 10.4544C6.31668 10.4544 6.31668 10.4544 6.31668 10.4544C6.3167 10.4544 6.31671 10.4543 6.31673 10.4542L6.65279 9.16085C6.66623 9.10912 6.69324 9.06193 6.73104 9.02414L11.7685 3.98775C11.8247 3.93149 11.901 3.89989 11.9806 3.8999ZM7.21298 9.39074L6.9335 10.4664L8.00819 10.1868L12.7758 5.4201L11.9805 4.62421L7.21298 9.39074ZM6.79414 10.5026C6.79421 10.5026 6.79428 10.5026 6.79436 10.5026L6.79414 10.5026Z" />
      <path fill={fill} fillRule="evenodd" clipRule="evenodd" d="M12.6499 3.09521L13.542 2.20316C13.9989 1.74617 14.7399 1.74617 15.1968 2.20316C15.6538 2.66015 15.6538 3.40107 15.1968 3.85805L14.3048 4.75011L12.6499 3.09521ZM13.4984 3.09521L14.3048 3.90158L14.7726 3.43379C14.9953 3.21112 14.9953 2.8501 14.7726 2.62742C14.5499 2.40475 14.1889 2.40475 13.9662 2.62742L13.4984 3.09521Z" />
    </svg>
  );
}
