type Props = {
  size?: number;
}

export default function List({ size = 64 }: Props) {
  return(
    <svg width={size} height={size} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M19.8551 20.0234C19.2682 20.0234 18.7925 20.4992 18.7925 21.086C18.7925 21.6729 19.2682 22.1486 19.8551 22.1486H20.0534C20.6403 22.1486 21.116 21.6729 21.116 21.086C21.116 20.4992 20.6403 20.0234 20.0534 20.0234H19.8551ZM19.8551 29.9411C19.2682 29.9411 18.7925 30.4168 18.7925 31.0037C18.7925 31.5905 19.2682 32.0663 19.8551 32.0663H20.0534C20.6403 32.0663 21.116 31.5905 21.116 31.0037C21.116 30.4168 20.6403 29.9411 20.0534 29.9411H19.8551ZM18.7925 40.9213C18.7925 40.3344 19.2682 39.8587 19.8551 39.8587H20.0534C20.6403 39.8587 21.116 40.3344 21.116 40.9213C21.116 41.5082 20.6403 41.9839 20.0534 41.9839H19.8551C19.2682 41.9839 18.7925 41.5082 18.7925 40.9213ZM25.8101 39.8587C25.2232 39.8587 24.7475 40.3344 24.7475 40.9213C24.7475 41.5082 25.2232 41.9839 25.8101 41.9839H45.6453C46.2322 41.9839 46.7079 41.5082 46.7079 40.9213C46.7079 40.3344 46.2322 39.8587 45.6453 39.8587H25.8101ZM24.7475 31.0037C24.7475 30.4168 25.2232 29.9411 25.8101 29.9411H45.6453C46.2322 29.9411 46.7079 30.4168 46.7079 31.0037C46.7079 31.5905 46.2322 32.0663 45.6453 32.0663H25.8101C25.2232 32.0663 24.7475 31.5905 24.7475 31.0037ZM25.8101 20.0234C25.2232 20.0234 24.7475 20.4992 24.7475 21.086C24.7475 21.6729 25.2232 22.1486 25.8101 22.1486H45.6453C46.2322 22.1486 46.7079 21.6729 46.7079 21.086C46.7079 20.4992 46.2322 20.0234 45.6453 20.0234H25.8101Z" fill="currentColor" />
      <rect x="13.9062" y="13.1523" width="37.687" height="37.687" rx="4.72268" stroke="currentColor" strokeWidth="2.12521" strokeLinecap="round"/>
    </svg>
  );
}