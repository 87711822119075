interface Props {
  className?: string;
  size?: string;
}

export default function ErrorIcon(props: Props) {
  const { size = 80, className } = props;
  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="10.6641"
        y="10.6665"
        width="58.6667"
        height="58.6667"
        rx="29.3333"
        fill="#FA4569"
      />
      <path
        d="M26.6641 53.333L52.2444 26.6673"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M27.7578 26.6665L53.3382 53.3322"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
      />
    </svg>
  );
}
