import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {
  size?: string | number;
}

export default function Smiley(props: Props) {
  const { size = 24 } = props;

  return (
    <svg width={size} height={size} {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2ZM12 4C16.418 4 20 7.582 20 12C20 16.418 16.418 20 12 20C7.582 20 4 16.418 4 12C4 7.582 7.582 4 12 4ZM8.5 8C7.672 8 7 8.672 7 9.5V10H10V9.5C10 8.672 9.328 8 8.5 8ZM15.5 8C14.672 8 14 8.672 14 9.5V10H17V9.5C17 8.672 16.328 8 15.5 8ZM6.89062 12C7.69063 15.206 9.67 17.5 12 17.5C14.33 17.5 16.3094 15.206 17.1094 12H6.89062Z" fill="currentColor" />
    </svg>
  );
}